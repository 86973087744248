import React from 'react';
import { Grid } from "react-loader-spinner";
import { Row, Col } from "reactstrap";

export default function Loader(props) {
  return (
    <Row className="w-50 m-5">
      <Col>
        <Grid ariaLabel="loading-indicator" color="#1B43BD" />
      </Col>
    </Row>
  );
}
