import React from "react";

const Status = props => {
  const statusClass = props.evaluationStatusDisplay
    ? props.evaluationStatusDisplay.toLowerCase()
    : "";

  return (
    <span className={`status ${statusClass}`}>
      {props.evaluationStatusDisplay}
    </span>
  );
};

export default Status;
