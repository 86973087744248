import React, { useState } from "react";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import Alert from "react-s-alert";
import { api } from "../helpers";
import logo from "../img/logo_large.png";
import { Link } from "react-router-dom";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const submitRequest = (e) => {
    if (e) {
      e.preventDefault();
    }
    const errorMessage = "There was an error requesting a password reset";

    if (!email) {
      Alert.warning("Email is required");
      return;
    }

    api
      .post("public/forgotpassword", JSON.stringify(email))
      .then((response) => {
        if (!response.data.result) {
          if (response.data.errorMessages) {
            Alert.error(response.data.errorMessages.join("<br/>"));
          } else {
            Alert.error(errorMessage);
          }
        } else {
          Alert.success("A password reset email has been sent");
          setShowSuccessMessage(true);
        }
      })
      .catch((err) => Alert.error(errorMessage));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitRequest();
    }
  };

  return (
    <Container>
      <Row className="login-logo-container">
        <Col className="center-col">
          <img src={logo} alt="Veracity" className="App-logo" />
        </Col>
      </Row>
      {showSuccessMessage ? (
        <>
          <Row className="row-top-spacer">
            <Col id="passwordResetLinkMessage" className="center-col">
              A password reset link has been sent to {email}.
            </Col>
          </Row>
          <Row className="row-top-spacer">
            <Col id="checkEmailMessage" className="center-col">
              Please check your email and spam folder for the reset
              instructions.
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className="row-top-spacer">
            <Col xs={10} sm={6} className="center-col">
              <h5 id="forgotPasswordTitle">Forgot Password Request</h5>
            </Col>
          </Row>
          <Row className="row-top-spacer">
            <Col xs={8} sm={4} className="center-col">
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  id="forgotPasswordEmail"
                  className="login"
                  name="email"
                  type="text"
                  placeholder="Enter email address"
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="row-top-spacer">
            <Col xs={7} className="center-col">
              <button id="requestPasswordResetButton" onClick={(e) => submitRequest(e)} className="site">
                REQUEST RESET
              </button>
            </Col>
          </Row>
        </>
      )}
      <Row className="row-top-spacer">
        <Col xs={6} className="center-col">
          <Link id="backToSignInButton" className="small-link" to="/login">
            Back to Sign In
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
