import React, { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardTitle,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,  
} from "reactstrap";
import { DebounceInput } from "react-debounce-input";
import Alert from "react-s-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { api, validator } from "../helpers";
import { Loader } from "./";

const Users = props => {
  const [users, setUsers] = useState(null);
  const [page] = useState(1);
  const [userToAdd, setUserToAdd] = useState(null);
  const [userToEdit, setUserToEdit] = useState(null);
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [productOptions, setProductOptions] = useState(false);
  const [firstNameFilter, setFirstNameFilter] = useState(null);
  const [lastNameFilter, setLastNameFilter] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const usersApiRoot = "user";
  const productsApiRoot = "product";

  const addUser = (e, empty) => {
    if (e) {
      e.preventDefault();
    }

    setUserToEdit(null);

    const newUser = empty
      ? null
      : {
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          passwordConfirmation: "",
          role: "1",
          selectedProductOptions: []
        };

    setUserToAdd(newUser);
  };

  const editUser = (e, user) => {
    if (e) {
      e.preventDefault();
    }

    setUserToAdd(null);

    if (!user) {
      setUserToEdit(null);
      return;
    }
    window.scrollTo(0, 0);
    setUserToEdit({
      ...user,
      password: "",
      passwordConfirmation: "",
      selectedProductOptions:
        productOptions &&
        productOptions.filter(
          x => user.productIds && user.productIds.includes(x.value)
        )
    });
  };

  const loadProducts = () => {
    const data = {
      Page: page
    };

    api
      .post(`${productsApiRoot}/products`, data)
      .then(response => {
        const productOptions = response.data.dataSource.map(x => {
          return { value: x.product.id, label: x.product.name };
        });
        setProductOptions(productOptions);
      })
      .catch(error => {
        Alert.error("There was an error loading the products list");
      });
  };

  const loadUsers = () => {
    setLoading(true);
    let data = {
      Page: page
    };
    if (firstNameFilter && firstNameFilter.length > 0) {
      data.FirstName = firstNameFilter
    }
    if (lastNameFilter && lastNameFilter.length > 0) {
      data.LastName = lastNameFilter
    }
    api
      .post(`${usersApiRoot}/users`, data)
      .then(response => {
        setUsers(response.data.dataSource);
        setUsersLoaded(true);
      })
      .catch(error => {
        Alert.error("There was an error loading the users list");
      })
      .finally(() => setLoading(false));
  };

  const saveNewUser = e => {
    e.preventDefault();
    const isAdmin = userToAdd.role === "2" || userToAdd.role === 2;

    let validationErrors = validator.validateNotNullStrings(
      ["First Name", "Last Name", "Email", "Password", "Password Confirmation"],
      [
        userToAdd.firstName,
        userToAdd.lastName,
        userToAdd.email,
        userToAdd.password,
        userToAdd.passwordConfirmation
      ]
    );

    if (
      !isAdmin &&
      (!userToAdd.selectedProductOptions ||
        userToAdd.selectedProductOptions.length === 0)
    ) {
      validationErrors.push(
        "Assignment of at least one product is required for regular users"
      );
    }

    if (validationErrors.length > 0) {
      Alert.warning(validationErrors.join("<br/>"));
      return;
    }

    if (userToAdd.password !== userToAdd.passwordConfirmation) {
      Alert.warning("Password and password confirmation do not match");
      return;
    }

    setSaving(true);
    const newUser = {
      FirstName: userToAdd.firstName,
      LastName: userToAdd.lastName,
      Email: userToAdd.email,
      Password: userToAdd.password,
      Role: parseInt(userToAdd.role, 10),
      ProductIds:
        isAdmin || !userToAdd.selectedProductOptions
          ? []
          : userToAdd.selectedProductOptions.map(x => parseInt(x.value, 10))
    };

    api
      .post(usersApiRoot, newUser)
      .then(response => {
        if (!response.data.hasErrorMessages) {
          Alert.success("New user saved");
          addUser(null, true);
          loadUsers();
        } else {
          Alert.error(response.data.errorMessages.join("<br/>"));
          return;
        }
      })
      .catch(error => {
        Alert.error("There was an error saving the new user");
      })
      .finally(() => setSaving(false));
  };

  const updateUser = e => {
    e.preventDefault();

    const isAdmin = userToEdit.role === "2" || userToEdit.role === 2;

    let validationErrors = validator.validateNotNullStrings(
      ["First Name", "Last Name", "Email"],
      [userToEdit.firstName, userToEdit.lastName, userToEdit.email]
    );

    if (userToEdit.password) {
      if (userToEdit.password !== userToEdit.passwordConfirmation) {
        validationErrors.push(
          "Password and password confirmation do not match"
        );
      }
    }

    if (
      !isAdmin &&
      (!userToEdit.selectedProductOptions ||
        userToEdit.selectedProductOptions.length === 0)
    ) {
      validationErrors.push(
        "Assignment of at least one product is required for regular users"
      );
    }

    if (validationErrors.length > 0) {
      Alert.warning(validationErrors.join("<br/>"));
      return;
    }

    const data = {
      Id: userToEdit.id,
      FirstName: userToEdit.firstName,
      LastName: userToEdit.lastName,
      Email: userToEdit.email,
      Password: userToEdit.password || "",
      Role: parseInt(userToEdit.role, 10),
      ProductIds:
        isAdmin || !userToEdit.selectedProductOptions
          ? []
          : userToEdit.selectedProductOptions.map(x => parseInt(x.value, 10))
    };

    setSaving(true);
    api
      .put(usersApiRoot, data)
      .then(response => {
        if (!response.data.hasErrorMessages) {
          Alert.success("User updated");
          editUser(null, null);
          loadUsers();
        } else {
          Alert.error(response.data.errorMessages.join("<br/>"));
          return;
        }
      })
      .catch(error => {
        Alert.error("There was an error updating the user");
      })
      .finally(() => setSaving(false));
  };

  const deactivateUser = (e, id) => {
    if (e) {
      e.preventDefault();
    }

    setUserToAdd(null);
    setUserToEdit(null);

    confirmAlert({
      title: "Deactivate User",
      message: "Are you sure you want to deactivate the user?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setSaving(true);
            api
              .delete(`${usersApiRoot}?userId=${id}`)
              .then(response => {
                Alert.success("User deactivated");
                loadUsers();
              })
              .catch(error => {
                Alert.error("There was an error deleting the user");
              })
              .finally(() => setSaving(false));
          }
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    });
  };

  const handleAddUserProductsChange = value => {
    setUserToAdd({
      ...userToAdd,
      selectedProductOptions: value
    });
  };

  const handleEditUserProductsChange = value => {
    setUserToEdit({
      ...userToEdit,
      selectedProductOptions: value
    });
  };

  window.pendo.initialize({
    visitor: {
        id:              props.currentUser.id,//'VISITOR-UNIQUE-ID'   // Required if user is logged in
        email:          props.currentUser.email,
        first_name: props.currentUser.firstName,
        last_name: props.currentUser.lastName,
        role: props.currentUser.role,
        has_pro_products: props.currentUser.hasProProducts,
        is_administrator: props.currentUser.isAdministrator,
        product_ids: props.currentUser.productIds,
        product_names: props.currentUser.productNames   // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
    },

    account: {
        id:           'ACCOUNT-UNIQUE-ID' // Required if using Pendo Feedback
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
    }
});

  useEffect(loadUsers, []);
  useEffect(loadProducts, []);

  useEffect(() => {
    loadUsers();
  }, [firstNameFilter, lastNameFilter]);

  return (
    <Container className="px-5">
      <Row>
        <div className="user-header-container">
          <h5 id="userAdministrationPageTitle">User Administration</h5>
        </div>
        <Col className="page-rule-container">
          <hr />
        </Col>
      </Row>
      {usersLoaded && !loading ? (
        <React.Fragment>
          <Row className="w-100">
            <Col xs="4">
              First Name
              <DebounceInput
                type="text"
                name="firstNameFilter"
                id="firstNameFilter"
                maxLength={30}
                onChange={(event) =>
                  setFirstNameFilter(
                    event.target.value
                  )
                }
                value={firstNameFilter || ""}
                className="form-control"
                debounceTimeout={300}
              />
            </Col>
            <Col xs="4">
              Last Name
              <DebounceInput
                type="text"
                name="lastNameFilter"
                id="lastNameFilter"
                maxLength={30}
                onChange={(event) =>
                  setLastNameFilter(
                    event.target.value
                  )
                }
                value={lastNameFilter || ""}
                className="form-control"
                debounceTimeout={300}
              />
            </Col>
            <Col xs={4}>
              <div className="float-right">
                <button id="addUsersButton" onClick={e => addUser(e)} className="small-link-button">
                  Add Users{" "}
                  <FontAwesomeIcon
                    icon="plus-circle"
                    className="ml-1 mt-0 mb-0 mr-2 pointer green"
                  />
                </button>
              </div>
            </Col>
          </Row>
          {userToAdd && (
            <Card>
              <CardBody>
                <CardTitle id="addANewUserTitle" tag="h5">Add A New User</CardTitle>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="firstname">First Name *</Label>
                      <Input
                        id="addUserFirstNameTextbox"
                        maxLength="30"
                        value={userToAdd.firstName}
                        name="firstname"
                        type="text"
                        placeholder="Enter user first name"
                        onChange={e =>
                          setUserToAdd({
                            ...userToAdd,
                            firstName: e.target.value
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="lastname">Last Name *</Label>
                      <Input
                        id="addUserLastNameTextbox"
                        maxLength="30"
                        value={userToAdd.lastName}
                        name="lastname"
                        type="text"
                        placeholder="Enter user first name"
                        onChange={e =>
                          setUserToAdd({
                            ...userToAdd,
                            lastName: e.target.value
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="badge">Email *</Label>
                      <Input
                        id="addNewUserEmailTextBox"
                        maxLength="50"
                        value={userToAdd.email}
                        name="badge"
                        type="text"
                        placeholder="Enter user email"
                        onChange={e =>
                          setUserToAdd({
                            ...userToAdd,
                            email: e.target.value
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="role">Role *</Label>
                      <select
                        id="addNewUserRoleDropdown"
                        className="form-control"
                        value={userToAdd.role}
                        name="role"
                        onChange={e =>
                          setUserToAdd({
                            ...userToAdd,
                            role: e.target.value
                          })
                        }
                      >
                        <option value="1">User</option>
                        <option value="2">Administrator</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="password">Password *</Label>
                      <Input
                        id="addNewUserPasswordTextbox"
                        value={userToAdd.password}
                        name="password"
                        type="password"
                        onChange={e =>
                          setUserToAdd({
                            ...userToAdd,
                            password: e.target.value
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="passwordConfirmation">
                        Password Confirmation *
                      </Label>
                      <Input
                        id="addNewUserPasswordConfirmationTextbox"
                        value={userToAdd.passwordConfirmation}
                        name="passwordConfirmation"
                        type="password"
                        onChange={e =>
                          setUserToAdd({
                            ...userToAdd,
                            passwordConfirmation: e.target.value
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {(userToAdd.role === 1 || userToAdd.role === "1") && (
                  <Row>
                    <Col xs={12}>
                      <FormGroup>
                        <Label for="products">Products</Label>
                        <Select
                          id="addNewUserSelectProductDropdown"
                          isMulti={true}
                          name="products"
                          options={productOptions}
                          value={userToAdd.selectedProductOptions || []}
                          onChange={e => handleAddUserProductsChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12}>
                    <button
                      id="addNewUserButton"
                      onClick={e => saveNewUser(e)}
                      className="site right"
                      disabled={saving}
                    >
                      Save New User
                    </button>
                    <button
                      id="addNewUserCancelButton"
                      onClick={e => addUser(e, true)}
                      className="cancel left-button right"
                      disabled={saving}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
          {userToEdit && (
            <Card>
              <CardBody>
                <CardTitle id="updateUserTitle" tag="h5">Update User</CardTitle>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="firstname">First Name *</Label>
                      <Input
                        id="updateUserFirstNameTextbox"
                        maxLength="30"
                        value={userToEdit.firstName || ""}
                        name="firstname"
                        type="text"
                        placeholder="Enter user first name"
                        onChange={e =>
                          setUserToEdit({
                            ...userToEdit,
                            userToEdit: e.target.value
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="lastname">Last Name *</Label>
                      <Input
                        id="updateUserLastNameTextbox"
                        maxLength="30"
                        value={userToEdit.lastName || ""}
                        name="lastname"
                        type="text"
                        placeholder="Enter user first name"
                        onChange={e =>
                          setUserToEdit({
                            ...userToEdit,
                            lastName: e.target.value
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="badge">Email *</Label>
                      <Input
                        id="updateUserEmail"
                        maxLength="50"
                        value={userToEdit.email || ""}
                        name="badge"
                        type="text"
                        placeholder="Enter user email"
                        onChange={e =>
                          setUserToEdit({
                            ...userToEdit,
                            email: e.target.value
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="role">Role *</Label>
                      <select
                        id="updateUserRoleDropdown"
                        className="form-control"
                        value={userToEdit.role || "1"}
                        name="role"
                        onChange={e =>
                          setUserToEdit({
                            ...userToEdit,
                            role: e.target.value
                          })
                        }
                      >
                        <option value="1">User</option>
                        <option value="2">Administrator</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input
                        id="updateUserPasswordTextbox"
                        value={userToEdit.password || ""}
                        name="password"
                        type="password"
                        onChange={e =>
                          setUserToEdit({
                            ...userToEdit,
                            password: e.target.value
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="passwordConfirmation">
                        Password Confirmation
                      </Label>
                      <Input
                        id="updateUserConfirmPasswordTextbox"
                        value={userToEdit.passwordConfirmation || ""}
                        name="passwordConfirmation"
                        type="password"
                        onChange={e =>
                          setUserToEdit({
                            ...userToEdit,
                            passwordConfirmation: e.target.value
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {(userToEdit.role === 1 || userToEdit.role === "1") && (
                  <Row>
                    <Col xs={12}>
                      <FormGroup>
                        <Label for="products">Products</Label>
                        <Select
                          id="updateUserProducts"
                          isMulti={true}
                          name="products"
                          options={productOptions}
                          value={userToEdit.selectedProductOptions || []}
                          onChange={e => handleEditUserProductsChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12}>
                    <button id="updateUserButton" onClick={e => updateUser(e)} className="site right">
                      Update User
                    </button>
                    <button
                      id="updateUserCancelButton"
                      onClick={e => editUser(e, null)}
                      className="cancel left-button right"
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
          <Row>
            {users && users.length && users.length > 0 ? (
              users.map(x => (
                <Col key={x.id} xs={12} sm={6} className="row-top-spacer">
                  <Card>
                    <CardBody>
                      <CardTitle tag="h5">
                        {x.firstName} {x.lastName}
                        <div className="right">
                          <button
                            id={`deactivateUserButton-${x.id}`}
                            onClick={e => deactivateUser(e, x.id)}
                            className="small-link-button admin-hover"
                          >
                            <FontAwesomeIcon
                              icon="trash-alt"
                              className="ml-1 mt-0 mb-0 mr-2 pointer coral"
                            />
                          </button>
                          <button
                            id={`editUserButton-${x.id}`}
                            onClick={e => editUser(e, x)}
                            className="small-link-button admin-hover"
                          >
                            <FontAwesomeIcon
                              icon="edit"
                              className="ml-1 mt-0 mb-0 mr-2 pointer blue"
                            />
                          </button>
                        </div>
                      </CardTitle>
                      <Row className="user-details">
                        <Col id={`userEmail-${x.id}`} xs={12}>{x.email}</Col>
                      </Row>
                      <hr className="email-rule" />
                      {x.isAdministrator ? (
                        <Row>
                          <Col xs={12}>
                            <Badge color="warning">Administrator</Badge>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col id={`userProducts-${x.id}`} xs={12}>
                            Products:{" "}
                            {x.productNames && x.productNames.join(", ")}
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              ))
            ) : (
              <Col id="noActiveUsersTitle" className="notification">There are no active users</Col>
            )}
          </Row>
        </React.Fragment>
      ) : <Loader/>}
    </Container>
  );
};

export default Users;
