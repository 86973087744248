import React, { useState } from "react";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import Alert from "react-s-alert";
import { api } from "../helpers";
import logo from "../img/logo_large.png";
import { Link } from "react-router-dom";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = (e) => {
    if (e) {
      e.preventDefault();
    }
    const errorMessage = "There was an error logging in";

    if (!email || !password) {
      Alert.warning("Email and password are required");
      return;
    }

    api
      .post("public/authenticate", {
        Email: email,
        Password: password,
      })
      .then((response) => {
        if (response.data.result === null) {
          if (response.data.errorMessages) {
            Alert.error(response.data.errorMessages.join("<br/>"));
          } else {
            Alert.error(errorMessage);
          }
        } else {
          props.setCurrentUser(response.data.result);
        }
      })
      .catch((err) => Alert.error(errorMessage));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      login();
    }
  };

  return (
    <Container>
      <Row className="login-logo-container">
        <Col className="center-col">
          <img id="logoLogin" src={logo} alt="Veracity" className="App-logo" />
        </Col>
      </Row>
      <Row className="row-top-spacer">
        <Col xs={8} sm={6} className="center-col">
          <h5 id="loginPageTitle">Member Provider Portal</h5>
        </Col>
      </Row>
      <Row className="row-top-spacer">
        <Col xs={7} sm={4} className="center-col">
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              id="LoginEmailAddressTextbox"
              className="login"
              name="email"
              type="text"
              placeholder="Enter email address"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              id="LoginPasswordTextbox"
              className="login"
              name="password"
              type="password"
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="row-top-spacer">
        <Col xs={6} className="center-col">
            <span>By signing in you agree to the <Link style={{ color: "919191"}} to='/termsAndConditions'>Terms and Conditions</Link></span>
        </Col>
      </Row>
      <Row className="row-top-spacer">
        <Col xs={6} className="center-col">
          <button id="signInButton" onClick={(e) => login(e)} className="site">
            SIGN IN
          </button>
        </Col>
      </Row>
      <Row className="row-top-spacer">
        <Col xs={6} className="center-col">
          <Link id="forgotPasswordLink" className="small-link" to="/forgotpassword">
            Forgot password?
          </Link>
        </Col>
      </Row>
      <Row className="row-top-spacer">
        <Col xs={6} className="center-col">
          <a id="dontHaveAccountLink" className="small-link" href="https://www.veracityvs.com/about/contact/">
            Don't have an account?
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
