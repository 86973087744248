import React, { Component } from "react";
import { Route, Redirect } from "react-router";
import { constants } from "./helpers";
import {
  ChangePassword,
  EvaluationReport,
  ForgotPassword,
  Layout,
  Login,
  Product,
  Products,
  Users,
  PrivacyPolicy,
  TermsAndConditions
} from "./components";

import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/stackslide.css";
import "./custom.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowDown,
  faArrowUp,
  faEdit,
  faExternalLinkAlt,
  faFile,
  faHeart,
  faInfoCircle,
  faMinusSquare,
  faPlusCircle,
  faPlusSquare,
  faQuestion,
  faSignOutAlt,
  faSpinner,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
library.add(faArrowDown);
library.add(faArrowUp);
library.add(faEdit);
library.add(faExternalLinkAlt);
library.add(faFile);
library.add(faHeart);
library.add(faInfoCircle);
library.add(faMinusSquare);
library.add(faPlusCircle);
library.add(faPlusSquare);
library.add(faQuestion);
library.add(faSignOutAlt);
library.add(faTimes);
library.add(faTrashAlt);
library.add(faSpinner);

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);
    const token = localStorage.getItem(constants.Token);
    let currentUser = token
      ? JSON.parse(localStorage.getItem(constants.CurrentUser))
      : null;
    this.state = {
      authenticationToken: token,
      currentUser: currentUser,
      appVersion: localStorage.getItem("version") || "",
      message: null,
    };
    this.logoff = this.logoff.bind(this);
    this.setCurrentUser = this.setCurrentUser.bind(this);
  }

  logoff() {
    const { Token, CurrentUser } = constants;

    localStorage.removeItem(Token);
    localStorage.removeItem(CurrentUser);

    this.setState({
      currentUser: null,
      authenticationToken: null,
    });
  }

  setCurrentUser(user) {
    const { Token, CurrentUser } = constants;

    localStorage.setItem(CurrentUser, JSON.stringify(user));
    let newState = {
      currentUser: user,
    };
    if (user.token) {
      localStorage.setItem(Token, user.token);
      newState.authenticationToken = user.token;
    }
    this.setState(newState);
  }

  render() {
    const AuthRoute = ({ component: Component, ...extraProps }) => {
      return (
        <Route
          {...extraProps}
          render={(props) => {
            return this.state.authenticationToken ? (
              <Component {...extraProps} currentUser={this.state.currentUser} />
            ) : (
              <Redirect to="/login" />
            );
          }}
        />
      );
    };

    const LoginRoute = ({ ...extraProps }) => (
      <Route
        {...extraProps}
        render={(props) => {
            return this.state.authenticationToken ? (
              <Redirect to="/" />
          ) : (
            <Login {...extraProps} setCurrentUser={this.setCurrentUser} />
          );
        }}
      />
    );

   
  

    return (
      <Layout
        currentUser={this.state.currentUser}
        logoff={this.logoff}
        appVersion={this.state.appVersion}
        showMenu={this.state.authenticationToken}
      >
        <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
        <Route exact path="/termsAndConditions" component={TermsAndConditions} />
        <LoginRoute exact path="/login" currentUser={this.state.currentUser} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <Route exact path="/changepassword" component={ChangePassword} />
        <AuthRoute exact path="/" component={Products} logoff={this.logoff} />
        <AuthRoute
          exact
          path="/evaluationreport/:productevaluationid/:mode"
          component={EvaluationReport}
        />
        <AuthRoute exact path="/product/:productid" component={Product} />
        <AuthRoute exact path="/users" component={Users} />
        <Alert
          effect="stackslide"
          position="top-right"
          stack={{ limit: 5 }}
          timeout={7000}
          html={true}
        />
      </Layout>
    );
  }
}
