import React from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

export default function Footer(props) {
  return (
    <div className="footer">
      <Row className="w-100">
        <Col xs="1" lg="2" xl="3"/>
        <Col xs="10" lg="8" xl="6" className="align-self-center justify-items-center justify-content-center text-center">
          <span>
            <div>Copyright &copy; 2022 Veracity Verification Solutions, LLC &nbsp;{" "}</div>
            <div><Link style={{ color: "919191" }} to='/privacyPolicy'>Privacy Policy</Link></div>
            <div><Link style={{ color: "919191" }} to='/termsAndConditions' className="ml-2">Terms and Conditions</Link></div>
          </span>
        </Col>
      </Row>
    </div>
  );
}
