import React from "react";

const Score = props => {
  if (!props.overallScore) {
    return <span className="score-number">{"N/A"}</span>;
  }

  const scoreNumber = props.scoreNumber
    ? props.scoreNumber
    : props.overallScore.toString().split(".")[0];

  let scoreDecimal = props.scoreDecimal;
  if (!props.scoreDecimal) {
    const parts = props.overallScore.toString().split(".");
    if (parts && parts.length > 1) {
      scoreDecimal = parts[1];
    } else {
      scoreDecimal = "0";
    }
  }

  return (
    <React.Fragment>
      <span className="score-number">
        {" "}
        {scoreNumber}
        {"."}
      </span>
      <span className="score-decimal">{scoreDecimal}</span>
      <span className="score-out-of">{props.outOf || "/5"}</span>
    </React.Fragment>
  );
};

export default Score;
