import React, { useState, useEffect } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import Alert from "react-s-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { api, validator } from "../helpers";
import { Score, Status } from ".";
import defaultProduct from "../img/default-product.png";
import Toggle from "react-toggle";
import { Loader } from "./";
import { DebounceInput } from "react-debounce-input";

const Products = (props) => {
  const [products, setProducts] = useState(null);
  const [page] = useState(1);
  const [productToAdd, setProductToAdd] = useState(null);
  const [productToEdit, setProductToEdit] = useState(null);
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [nameFilter, setNameFilter] = useState(null);

  const productsApiRoot = "product";

  const addProduct = (e, empty) => {
    if (e) {
      e.preventDefault();
    }

    setProductToEdit(null);

    const newProduct = empty
      ? null
      : {
          name: "",
          description: "",
          provider: "",
          isPro: false,
        };

    setProductToAdd(newProduct);
  };

  const editProduct = (e, product) => {
    if (e) {
      e.preventDefault();
    }

    setProductToAdd(null);

    if (!product) {
      setProductToEdit(null);
      return;
    }

    setProductToEdit({ ...product });
  };

  const loadProducts = () => {
    let data = {
      Page: page,
    };
    if (nameFilter && nameFilter.length > 0) {
      data.Name = nameFilter;
    }
    setLoading(true);
    api
      .post(`${productsApiRoot}/products`, data)
      .then((response) => {
        setProducts(response.data.dataSource);
        setProductsLoaded(true);
      })
      .catch((error) => {
        Alert.error("There was an error loading the products list");
      })
      .finally(() => setLoading(false));
  };

  const saveNewProduct = (e) => {
    e.preventDefault();
    const validationErrors = validator.validateNotNullStrings(
      ["Product Name", "Product Provider", "Product Description"],
      [productToAdd.name, productToAdd.provider, productToAdd.description]
    );

    if (validationErrors.length > 0) {
      Alert.warning(validationErrors.join("<br/>"));
      return;
    }

    const formData = new FormData();
    formData.append("Name", productToAdd.name);
    formData.append("Provider", productToAdd.provider);
    formData.append("Description", productToAdd.description);
    formData.append("IsPro", productToAdd.isPro);
    if (productToAdd.iconFile) {
      formData.append("IconFile", document.querySelector("#add-icon").files[0]);
    }

    setSaving(true);
    api
      .postFormData(productsApiRoot, formData)
      .then((response) => {
        if (!response.data.hasErrorMessages) {
          Alert.success("New product saved");
          addProduct(null, true);
          loadProducts();
        } else {
          Alert.error(response.data.join("<br/>"));
          return;
        }
      })
      .catch((error) => {
        Alert.error("There was an error saving the new product");
      })
      .finally(() => setSaving(false));
  };

  const updateProduct = (e) => {
    e.preventDefault();
    const validationErrors = validator.validateNotNullStrings(
      ["Product Name", "Product Provider", "Product Description"],
      [productToEdit.name, productToEdit.provider, productToEdit.description]
    );

    if (validationErrors.length > 0) {
      Alert.warning(validationErrors.join("<br/>"));
      return;
    }

    const formData = new FormData();
    formData.append("Id", productToEdit.id);
    formData.append("Name", productToEdit.name);
    formData.append("Provider", productToEdit.provider);
    formData.append("Description", productToEdit.description);
    formData.append("IsPro", productToEdit.isPro);
    if (productToEdit.iconFile) {
      formData.append(
        "IconFile",
        document.querySelector("#edit-icon").files[0]
      );
    }

    setSaving(true);
    api
      .putFormData(productsApiRoot, formData)
      .then((response) => {
        if (!response.data.hasErrorMessages) {
          Alert.success("Product updated");
          editProduct(null, null);
          loadProducts();
        } else {
          Alert.error(response.data.join("<br/>"));
          return;
        }
      })
      .catch((error) => {
        Alert.error("There was an error updating the product");
      })
      .finally(() => setSaving(false));
  };

  const deactivateProduct = (e, id) => {
    if (e) {
      e.preventDefault();
    }

    setProductToAdd(null);
    setProductToEdit(null);

    confirmAlert({
      title: "Deactivate Product",
      message: "Are you sure you want to deactivate the product?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setSaving(true);
            api
              .delete(`${productsApiRoot}?productId=${id}`)
              .then((response) => {
                Alert.success("Product deactivated");
                loadProducts();
              })
              .catch((error) => {
                Alert.error("There was an error deleting the product");
              })
              .finally(() => setSaving(false));
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  window.pendo.initialize({
    visitor: {
      id: props.currentUser.id, //'VISITOR-UNIQUE-ID'   // Required if user is logged in
      email: props.currentUser.email,
      first_name: props.currentUser.firstName,
      last_name: props.currentUser.lastName,
      role: props.currentUser.role,
      has_pro_products: props.currentUser.hasProProducts,
      is_administrator: props.currentUser.isAdministrator,
      product_ids: props.currentUser.productIds,
      product_names: props.currentUser.productNames,
      // Recommended if using Pendo Feedback, or NPS Email
      // full_name:    // Recommended if using Pendo Feedback
      // role:         // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: "ACCOUNT-UNIQUE-ID", // Required if using Pendo Feedback
      // name:         // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    },
  });

  useEffect(loadProducts, []);

  useEffect(() => {
    loadProducts();
  }, [nameFilter]);

  return (
    <Container>
      <Row>
        <div className="page-header">
          <h5 id="myProductsTitle">My Products</h5>
        </div>
        <Col className="page-rule-container">
          <hr />
        </Col>
      </Row>
      <Row className="w-100">
        <Col xs="4">
          Search Product Name
          <DebounceInput
            type="text"
            name="nameFilter"
            id="nameFilter"
            maxLength={30}
            onChange={(event) => setNameFilter(event.target.value)}
            value={nameFilter || ""}
            className="form-control"
            debounceTimeout={300}
          />
        </Col>
        <Col xs="4" />
        {productsLoaded && props.currentUser.isAdministrator && (
          <Col xs={4} className="header-action-button float-right">
            <div className="float-right">
              <button
                id="addProductsButton"
                onClick={(e) => addProduct(e)}
                className="small-link-button"
              >
                Add Products{" "}
                <FontAwesomeIcon
                  icon="plus-circle"
                  className="ml-1 mt-0 mb-0 mr-2 pointer green"
                />
              </button>
            </div>
          </Col>
        )}
      </Row>
      {productsLoaded && !loading ? (
        <React.Fragment>
          {!props.currentUser.isAdministrator && (
            <Row>
              <Col xs={12} className="header-action-button">
                <div className="right">
                  <a
                    id="requestNewProductButton"
                    href="mailto:info@veracityvs.com"
                    className="small-link-button"
                  >
                    Request New Product{" "}
                    <FontAwesomeIcon
                      icon="plus-circle"
                      className="ml-1 mt-0 mb-0 mr-2 pointer green"
                    />
                  </a>
                </div>
              </Col>
            </Row>
          )}
          {props.currentUser.isAdministrator && productToAdd && (
            <Card>
              <CardBody>
                <CardTitle id="addANewProductTitle" tag="h5">
                  Add A New Product
                </CardTitle>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="name">Name *</Label>
                      <Input
                        id="newProductNameTextbox"
                        maxLength="100"
                        value={productToAdd.name}
                        name="name"
                        type="text"
                        placeholder="Enter product name"
                        onChange={(e) =>
                          setProductToAdd({
                            ...productToAdd,
                            name: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="provider">Provider *</Label>
                      <Input
                        id="ProviderNameTextbox"
                        maxLength="100"
                        value={productToAdd.provider}
                        name="provider"
                        type="text"
                        placeholder="Enter provider name"
                        onChange={(e) =>
                          setProductToAdd({
                            ...productToAdd,
                            provider: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Label for="description">Description *</Label>
                      <Input
                        id="productDescriptionTextbox"
                        rows={5}
                        value={productToAdd.description}
                        name="description"
                        type="textarea"
                        placeholder="Enter product description"
                        onChange={(e) =>
                          setProductToAdd({
                            ...productToAdd,
                            description: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="icon">Icon File (png)</Label>
                      <Input
                        id="add-icon"
                        rows={5}
                        name="icon"
                        value={productToAdd.iconFile}
                        type="file"
                        onChange={(e) =>
                          setProductToAdd({
                            ...productToAdd,
                            iconFile: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="pro">Pro Product</Label>
                      <div>
                        {" "}
                        <Toggle
                          id="add-pro"
                          defaultChecked={productToAdd.isPro}
                          onChange={(e) =>
                            setProductToAdd({
                              ...productToAdd,
                              isPro: e.target.checked,
                            })
                          }
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <button
                      id="saveNewProductButton"
                      onClick={(e) => saveNewProduct(e)}
                      className="site right"
                    >
                      Save New Product
                    </button>
                    <button
                      id="cancelNewProductButton"
                      onClick={(e) => addProduct(e, true)}
                      className="cancel button-left right"
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
          {props.currentUser.isAdministrator && productToEdit && (
            <Card>
              <CardBody>
                <CardTitle id="updateProductTitle" tag="h5">
                  Update Product
                </CardTitle>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="name">Name *</Label>
                      <Input
                        id="updateProductNameTextbox"
                        maxLength="100"
                        name="name"
                        type="text"
                        placeholder="Enter product name"
                        value={productToEdit.name}
                        onChange={(e) =>
                          setProductToEdit({
                            ...productToEdit,
                            name: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="provider">Provider *</Label>
                      <Input
                        id="updateProductProviderTextbox"
                        maxLength="100"
                        value={productToEdit.provider}
                        name="provider"
                        type="text"
                        placeholder="Enter provider name"
                        onChange={(e) =>
                          setProductToEdit({
                            ...productToEdit,
                            provider: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <FormGroup>
                      <Label for="description">Description *</Label>
                      <Input
                        id="updateProductDescriptionTextbox"
                        rows={5}
                        name="description"
                        value={productToEdit.description}
                        type="textarea"
                        placeholder="Enter product description"
                        onChange={(e) =>
                          setProductToEdit({
                            ...productToEdit,
                            description: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label for="icon">Icon File (png)</Label>
                      <Input
                        id="edit-icon"
                        rows={5}
                        name="icon"
                        value={productToEdit.iconFile || ""}
                        type="file"
                        onChange={(e) =>
                          setProductToEdit({
                            ...productToEdit,
                            iconFile: e.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="pro">Pro Product</Label>
                      <div>
                        {" "}
                        <Toggle
                          id="edit-pro"
                          defaultChecked={productToEdit.isPro}
                          onChange={(e) =>
                            setProductToEdit({
                              ...productToEdit,
                              isPro: e.target.checked,
                            })
                          }
                        />
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <button
                      id="updateProductButton"
                      onClick={(e) => updateProduct(e)}
                      className="site right"
                    >
                      Update Product
                    </button>
                    <button
                      id="updateProductCancelButton"
                      onClick={(e) => editProduct(e, null)}
                      className="cancel button-left right"
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
          <Row className="products-container">
            {products && products.length && products.length > 0 ? (
              products.map((x) => (
                <Col
                  className="product col-sm-3 offset-sm-1"
                  key={x.product.id}
                >
                  {props.currentUser.isAdministrator && (
                    <div className="admin-button-container">
                      <div>
                        <button
                          id={`deactivateProductButton-${x.product.id}`}
                          title="delete"
                          onClick={(e) => deactivateProduct(e, x.product.id)}
                          className="small-link-button admin-hover right"
                        >
                          <FontAwesomeIcon
                            icon="trash-alt"
                            className="ml-1 mt-0 mb-0 mr-2 pointer coral"
                          />
                        </button>
                        <button
                          id={`editProductButton-${x.product.id}`}
                          title="edit"
                          onClick={(e) => editProduct(e, x.product)}
                          className="small-link-button admin-hover left"
                        >
                          <FontAwesomeIcon
                            icon="edit"
                            className="ml-1 mt-0 mb-0 mr-2 pointer blue"
                          />
                        </button>
                      </div>
                    </div>
                  )}
                  {x.product.isPro && (
                    <>
                      <div className="pro-badge-container">
                        <span
                          id={`proBadgeTag-${x.product.id}`}
                          className="pro-badge"
                        >
                          PRO
                        </span>
                      </div>
                      <div className="clearfix"></div>
                    </>
                  )}
                  <div className="product-icon">
                    <img
                      id={`productIconImage-${x.product.id}`}
                      width="125px"
                      src={
                        x.product.hasIconBytes
                          ? `api/${productsApiRoot}/icon?productId=${x.product.id}`
                          : `${defaultProduct}`
                      }
                      title="product-icon"
                      alt="product-icon"
                    />
                  </div>
                  <div id={`productName-${x.product.id}`}>{x.product.name}</div>
                  <div
                    id={`productProvider-${x.product.id}`}
                    className="by-provider"
                  >
                    by {x.product.provider}
                  </div>
                  {x.productEvaluationHeader && (
                    <React.Fragment>
                      <div
                        id={`productScore-${x.product.id}`}
                        className="display-none"
                      >
                        <Score
                          overallScore={x.productEvaluationHeader.overallScore}
                          scoreNumber={x.productEvaluationHeader.scoreNumber}
                          scoreDecimal={x.productEvaluationHeader.scoreDecimal}
                        />
                      </div>
                      <Row>
                        <Col className="product-detail-label">
                          <span id={`productStatus-${x.product.id}`}>
                            Status
                          </span>
                        </Col>
                        <Col className="product-detail-value">
                          <span>
                            <Status
                              evaluationStatusDisplay={
                                x.productEvaluationHeader
                                  .evaluationStatusDisplay
                              }
                            />
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="product-detail-label">
                          <span id={`productValidThrough-${x.product.id}`}>
                            Valid Thru
                          </span>
                        </Col>
                        <Col className="product-detail-value">
                          <span className="status">
                            {x.productEvaluationHeader
                              .evaluationValidThroughDate
                              ? x.productEvaluationHeader
                                  .evaluationValidThroughDateDisplay
                              : "N/A"}
                          </span>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                  <div className="product-details-button-container">
                    <Link
                      id={`productDetailsButton-${x.product.id}`}
                      className="product-details-button"
                      to={`/product/${x.product.id}`}
                    >
                      Details
                    </Link>
                  </div>
                </Col>
              ))
            ) : (
              <Col id="noProductsTitle" className="notification">
                There are no products available in your account
              </Col>
            )}
          </Row>
        </React.Fragment>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default Products;
