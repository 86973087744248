import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Alert from "react-s-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { api, validator } from "../helpers";
import { Score, Status } from ".";
import defaultProduct from "../img/default-product.png";

const Product = props => {
  const [productId] = useState(useParams().productid);
  const [product, setProduct] = useState(null);
  const [verificationToAdd, setVerificationToAdd] = useState(null);
  const [verificationToEdit, setVerificationToEdit] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const productsApiRoot = "product";
  const evaluationApiRoot = "product/evaluation";

  const validateDecimal = decimalValue => {
    if (!decimalValue) {
      return true;
    }

    return decimalValue.toString().match(/^-?\d*(\.\d+)?$/);
  };

  const addVerification = (e, empty) => {
    if (e) {
      e.preventDefault();
    }

    setVerificationToEdit(null);

    const newVerification = empty
      ? null
      : {
          productId: productId,
          productVersion: "",
          evaluationStatus: "1",
          evaluationValidThroughDate: "",
          overallScore: "",
          serializedReportJson: "",
          serializedPublicReportJson: "",
          badge: ""
        };

    setVerificationToAdd(newVerification);
  };

  const editVerification = (e, verification) => {
    if (e) {
      e.preventDefault();
    }

    setVerificationToAdd(null);

    if (!verification) {
      setVerificationToEdit(null);
      return;
    }

    api
      .fetch(`${evaluationApiRoot}?productEvaluationId=${verification.id}`)
      .then(response => {
        setVerificationToEdit({
          ...response.data,
          serializedReportJson: response.data.serializedReportJson
            ? response.data.serializedReportJson
            : "",
          serializedPublicReportJson: response.data.serializedPublicReportJson
            ? response.data.serializedPublicReportJson
            : ""
        });
      })
      .catch(error => {
        Alert.error(
          "There was an error loading the product verification to edit"
        );
      });
  };

  const loadProduct = () => {
    api
      .fetch(`${productsApiRoot}?productId=${productId}`)
      .then(response => {
        setProduct(response.data);
      })
      .catch(error => {
        Alert.error("There was an error loading the product verifications");
      });
  };

  const saveNewVerification = e => {
    e.preventDefault();
    let validationErrors = validator.validateNotNullStrings(
      ["Product Version"],
      [verificationToAdd.productVersion]
    );

    if (
      verificationToAdd.overallScore &&
      !validateDecimal(verificationToAdd.overallScore)
    ) {
      validationErrors.push(
        "Overall Score must be a valid decimal number (ex: 4.5)"
      );
    }

    if (validationErrors.length > 0) {
      Alert.warning(validationErrors.join("<br/>"));
      return;
    }

    const data = {
      ProductId: productId,
      ProductVersion: verificationToAdd.productVersion,
      EvaluationStatus: verificationToAdd.evaluationStatus
        ? parseInt(verificationToAdd.evaluationStatus, 10)
        : null,
      EvaluationValidThroughDate: verificationToAdd.evaluationValidThroughDate
        ? verificationToAdd.evaluationValidThroughDate
        : null,
      OverallScore: verificationToAdd.overallScore
        ? parseFloat(verificationToAdd.overallScore, 10)
        : null,
      SerializedReportJson: verificationToAdd.serializedReportJson
        ? JSON.stringify(JSON.parse(verificationToAdd.serializedReportJson))
        : null,
      SerializedPublicReportJson: verificationToAdd.serializedPublicReportJson
        ? JSON.stringify(
            JSON.parse(verificationToAdd.serializedPublicReportJson)
          )
        : null,
      Badge: verificationToAdd.badge
    };

    api
      .post(evaluationApiRoot, data)
      .then(response => {
        if (!response.data.hasErrorMessages) {
          Alert.success("New verification saved");
          addVerification(null, true);
          loadProduct();
        } else {
          Alert.error(response.data.join("<br/>"));
          return;
        }
      })
      .catch(error => {
        Alert.error("There was an error saving the new verification");
      });
  };

  const updateVerification = e => {
    e.preventDefault();
    let validationErrors = validator.validateNotNullStrings(
      ["Product Version"],
      [verificationToEdit.productVersion]
    );

    if (
      verificationToEdit.overallScore &&
      !validateDecimal(verificationToEdit.overallScore)
    ) {
      validationErrors.push(
        "Overall Score must be a valid decimal number (ex: 4.5)"
      );
    }

    if (validationErrors.length > 0) {
      Alert.warning(validationErrors.join("<br/>"));
      return;
    }

    const data = {
      Id: verificationToEdit.id,
      ProductId: productId,
      ProductVersion: verificationToEdit.productVersion,
      EvaluationStatus: verificationToEdit.evaluationStatus
        ? parseInt(verificationToEdit.evaluationStatus, 10)
        : null,
      EvaluationValidThroughDate: verificationToEdit.evaluationValidThroughDate
        ? verificationToEdit.evaluationValidThroughDate
        : null,
      OverallScore: verificationToEdit.overallScore
        ? parseFloat(verificationToEdit.overallScore, 10)
        : null,
      SerializedReportJson: verificationToEdit.serializedReportJson
        ? JSON.stringify(JSON.parse(verificationToEdit.serializedReportJson))
        : null,
      SerializedPublicReportJson: verificationToEdit.serializedPublicReportJson
        ? JSON.stringify(
            JSON.parse(verificationToEdit.serializedPublicReportJson)
          )
        : null,
      Badge: verificationToEdit.badge
    };

    api
      .put(evaluationApiRoot, data)
      .then(response => {
        if (!response.data.hasErrorMessages) {
          Alert.success("Verification updated");
          editVerification(null, null);
          loadProduct();
        } else {
          Alert.error(response.data.join("<br/>"));
          return;
        }
      })
      .catch(error => {
        Alert.error("There was an error updating the verification");
      });
  };

  const deactivateVerification = (e, id) => {
    if (e) {
      e.preventDefault();
    }

    setVerificationToAdd(null);
    setVerificationToEdit(null);

    confirmAlert({
      title: "Deactivate Verification",
      message: "Are you sure you want to deactivate the verification?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            api
              .delete(`${evaluationApiRoot}?productEvaluationId=${id}`)
              .then(response => {
                Alert.success("Verification deactivated");
                loadProduct(productId);
              })
              .catch(error => {
                Alert.error("There was an error deleting the verification");
              });
          }
        },
        {
          label: "No",
          onClick: () => {}
        }
      ]
    });
  };

  const toggleModal = (toggleState, content) => {
    setModalContent(content);
    setShowModal(toggleState);
  };

  window.pendo.initialize({
    visitor: {
        id:              props.currentUser.id,//'VISITOR-UNIQUE-ID'   // Required if user is logged in
        email:          props.currentUser.email,
        first_name: props.currentUser.firstName,
        last_name: props.currentUser.lastName,
        role: props.currentUser.role,
        has_pro_products: props.currentUser.hasProProducts,
        is_administrator: props.currentUser.isAdministrator,
        product_ids: props.currentUser.productIds,
        product_names: props.currentUser.productNames   // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
    },

    account: {
        id:           'ACCOUNT-UNIQUE-ID' // Required if using Pendo Feedback
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
    }
});


  useEffect(loadProduct, []);

  return (
    <Container>
      {product && (
        <React.Fragment>
          <Row id="myProductsLink">
            <Col>
              <Link to={"/"}>My Products</Link> &gt; {product.name}
            </Col>
          </Row>
          <Row className="my-products-container">
            <Col id="productHeader" xs={12} className="product-header">
              <Row>
                <div className="detail-product-icon">
                  <img
                    src={
                      product.hasIconBytes
                        ? `api/${productsApiRoot}/icon?productId=${product.id}`
                        : `${defaultProduct}`
                    }
                    title="product-icon"
                    alt="product-icon"
                  />
                </div>
                <Col id="productNameContainer">
                  <div className="product-name-container">
                    <h2 className="product-name"> {product.name}</h2>
                  </div>
                  <div>by {product.provider}</div>
                </Col>
              </Row>

              <div className="product-description-container">
                {product.description}
              </div>
            </Col>
          </Row>
          <Row id="verificationsHeader" className="row-top-spacer">
            <div className="page-header">
              <h5>Verifications</h5>
            </div>
            <Col className="page-rule-container">
              <hr />
            </Col>
          </Row>
          {props.currentUser.isAdministrator ? (
            <Row>
              <Col id="addVerificationButton" xs={12} className="header-action-button">
                <div className="right">
                  <button
                    onClick={e => addVerification(e)}
                    className="small-link-button"
                  >
                    Add Verification{" "}
                    <FontAwesomeIcon
                      icon="plus-circle"
                      className="ml-1 mt-0 mb-0 mr-2 pointer green"
                    />
                  </button>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col id="requestNewVerificationButton" xs={12}>
                <div className="right">
                  <a
                    href="mailto:info@veracityvs.com"
                    className="small-link-button"
                  >
                    Request New Verification
                    <FontAwesomeIcon
                      icon="plus-circle"
                      className="ml-1 mt-0 mb-0 mr-2 pointer green"
                    />
                  </a>
                </div>
              </Col>
            </Row>
          )}
          {props.currentUser.isAdministrator &&
            verificationToAdd && (
              <Card>
                <CardBody id="addNewVerificationForm">
                  <CardTitle tag="h5">Add A New Verification</CardTitle>
                  <Row>
                    <Col xs={6}>
                      <FormGroup id="productVersionInputField">
                        <Label for="productVersion">Product Version *</Label>
                        <Input
                          maxLength="20"
                          value={verificationToAdd.productVersion}
                          name="productVersion"
                          type="text"
                          placeholder="Enter product version"
                          onChange={e =>
                            setVerificationToAdd({
                              ...verificationToAdd,
                              productVersion: e.target.value
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup id="verificationStatusInputField">
                        <Label for="evaluationStatus">
                          Verification Status
                        </Label>
                        <select
                          className="form-control"
                          value={verificationToAdd.evaluationStatus}
                          name="evaluationStatus"
                          onChange={e =>
                            setVerificationToAdd({
                              ...verificationToAdd,
                              evaluationStatus: e.target.value
                            })
                          }
                        >
                          <option value="1">Pending</option>
                          <option value="2">Active</option>
                          <option value="3">Expired</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <FormGroup id="validThroughDateInput">
                        <Label for="evaluationValidThroughDate">
                          Valid Through
                        </Label>
                        <div>
                          <DatePicker
                            className="form-control"
                            name="evaluationValidThroughDate"
                            selected={
                              verificationToAdd.evaluationValidThroughDate
                                ? Date.parse(
                                    verificationToAdd.evaluationValidThroughDate
                                  )
                                : ""
                            }
                            onChange={date =>
                              setVerificationToAdd({
                                ...verificationToAdd,
                                evaluationValidThroughDate: date
                              })
                            }
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup id="overallScoreInputField">
                        <Label for="overallscore">Overall Score</Label>
                        <Input
                          maxLength="3"
                          value={verificationToAdd.overallScore}
                          name="overallscore"
                          type="text"
                          onChange={e =>
                            setVerificationToAdd({
                              ...verificationToAdd,
                              overallScore: e.target.value
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup id="badgeInputField">
                        <Label for="badge">Badge</Label>
                        <Input
                          type="textarea"
                          rows={5}
                          value={verificationToAdd.badge}
                          name="serializedPublicReportJson"
                          onChange={e =>
                            setVerificationToAdd({
                              ...verificationToAdd,
                              badge: e.target.value
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup id="publicReportJsonField">
                        <Label for="serializedPublicReportJson">
                          Public Report Json
                        </Label>
                        <Input
                          type="textarea"
                          rows={5}
                          value={verificationToAdd.serializedPublicReportJson}
                          name="serializedPublicReportJson"
                          onChange={e =>
                            setVerificationToAdd({
                              ...verificationToAdd,
                              serializedPublicReportJson: e.target.value
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup id="privateReportJsonField">
                        <Label for="serializedReportJson">
                          Private Report Json
                        </Label>
                        <Input
                          type="textarea"
                          rows={5}
                          value={verificationToAdd.serializedReportJson}
                          name="serializedReportJson"
                          onChange={e =>
                            setVerificationToAdd({
                              ...verificationToAdd,
                              serializedReportJson: e.target.value
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <button
                        id="saveNewVerificationButton"
                        onClick={e => saveNewVerification(e)}
                        className="site right"
                      >
                        Save New Verification
                      </button>
                      <button
                        id="cancelNewVerificationButton"
                        onClick={e => addVerification(e, true)}
                        className="cancel left-button right"
                      >
                        Cancel
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          {props.currentUser.isAdministrator &&
            verificationToEdit && (
              <Card>
                <CardBody id="updateVerificationForm">
                  <CardTitle tag="h5">Update Verification</CardTitle>
                  <Row>
                    <Col xs={6}>
                      <FormGroup id="updateProducctVersionInputField">
                        <Label for="productVersion">Product Version *</Label>
                        <Input
                          maxLength="20"
                          value={verificationToEdit.productVersion}
                          name="productVersion"
                          type="text"
                          placeholder="Enter product version"
                          onChange={e =>
                            setVerificationToEdit({
                              ...verificationToEdit,
                              productVersion: e.target.value
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup id="updateVerificationStatusInputField">
                        <Label for="evaluationStatus">
                          Verification Status
                        </Label>
                        <select
                          className="form-control"
                          value={verificationToEdit.evaluationStatus}
                          name="evaluationStatus"
                          onChange={e =>
                            setVerificationToEdit({
                              ...verificationToEdit,
                              evaluationStatus: e.target.value
                            })
                          }
                        >
                          <option value="1">Pending</option>
                          <option value="2">Active</option>
                          <option value="3">Expired</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <FormGroup id="updateValidThroughDateInputField">
                        <Label for="evaluationValidThroughDate">
                          Valid Through
                        </Label>
                        <div>
                          <DatePicker
                            className="form-control"
                            name="evaluationValidThroughDate"
                            selected={
                              verificationToEdit.evaluationValidThroughDate
                                ? Date.parse(
                                    verificationToEdit.evaluationValidThroughDate
                                  )
                                : ""
                            }
                            onChange={date =>
                              setVerificationToEdit({
                                ...verificationToEdit,
                                evaluationValidThroughDate: date
                              })
                            }
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col xs={6}>
                      <FormGroup id="updateOverallScoreInputField">
                        <Label for="overallscore">Overall Score</Label>
                        <Input
                          maxLength="3"
                          value={verificationToEdit.overallScore}
                          name="overallscore"
                          type="text"
                          onChange={e =>
                            setVerificationToEdit({
                              ...verificationToEdit,
                              overallScore: e.target.value
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup id="updateBadgeInputField">
                        <Label for="badge">Badge</Label>
                        <Input
                          type="textarea"
                          rows={5}
                          value={verificationToEdit.badge}
                          name="serializedPublicReportJson"
                          onChange={e =>
                            setVerificationToEdit({
                              ...verificationToEdit,
                              badge: e.target.value
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup id="updatePublicReportJsonInputField">
                        <Label for="serializedPublicReportJson">
                          Public Report Json
                        </Label>
                        <Input
                          type="textarea"
                          rows={5}
                          value={verificationToEdit.serializedPublicReportJson}
                          name="serializedPublicReportJson"
                          onChange={e =>
                            setVerificationToEdit({
                              ...verificationToEdit,
                              serializedPublicReportJson: e.target.value
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <FormGroup id="updatePrivateReportJsonInputField">
                        <Label for="serializedReportJson">
                          Private Report Json
                        </Label>
                        <Input
                          type="textarea"
                          rows={5}
                          value={verificationToEdit.serializedReportJson}
                          name="serializedReportJson"
                          onChange={e =>
                            setVerificationToEdit({
                              ...verificationToEdit,
                              serializedReportJson: e.target.value
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <button
                        id="updateVerificationButton"
                        onClick={e => updateVerification(e)}
                        className="site right"
                      >
                        Update Verification
                      </button>
                      <button
                        id="cancelUpdateVerificationButton"
                        onClick={e => editVerification(e, null)}
                        className="cancel left-button right"
                      >
                        Cancel
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          {product.evaluations &&
            product.evaluations.length > 0 && (
              <Row id="evaluationHeader" className="evaluation-header">
                <Col xs={2} className="display-none" />
                <Col id="evaluationStatusColumn" xs={2}>Status</Col>
                <Col id="evaluationValidThroughColumn" xs={2}>Valid Through</Col>
                <Col id="evaluationSchoolDistrictReportAccessColumn" xs={3}>School District Report Access</Col>
                <Col id="evaluationVendorReportAccessColumn" xs={3}>Vendor Report Access</Col>
                <Col xs={2}></Col>
              </Row>
            )}
          {product.evaluations &&
            product.evaluations.length > 0 &&
            product.evaluations.map((x, i) => (
              <Row key={x.id} className="evaluation">
                <Col id="evaluationScoreContainer" xs={2} className="score-container display-none">
                  <Score
                    overallScore={x.overallScore}
                    scoreNumber={x.scoreNumber}
                    scoreDecimal={x.scoreDecimal}
                  />
                </Col>
                <Col id="evaluationStatusContainer" xs={2} className="status-container">
                  <Status evaluationStatusDisplay={x.evaluationStatusDisplay} />
                </Col>
                <Col id="evaluationValidThroughDateDisplay" xs={2} className="status-container">
                  <span className="status">
                    {x.evaluationValidThroughDateDisplay}
                  </span>{" "}
                </Col>
                <Col id="evaluationPublicReportJsonExternalLink" xs={3} className="row-top-spacer">
                  {x.hasSerializedPublicReportJson && (
                    <Link
                      className="external-link"
                      to={`/evaluationreport/${x.id}/public`}
                    >
                      <FontAwesomeIcon
                        icon="external-link-alt"
                        className="ml-1 mt-0 mb-0 mr-2 pointer"
                      />
                    </Link>
                  )}
                </Col>
                <Col id="evaluationPrivateReportJsonExternalLink" xs={3} className="row-top-spacer">
                  {x.hasSerializedReportJson && (
                    <Link
                      className="external-link"
                      to={`/evaluationreport/${x.id}/full`}
                    >
                      <FontAwesomeIcon
                        icon="external-link-alt"
                        className="ml-1 mt-0 mb-0 mr-2 pointer"
                      />
                    </Link>
                  )}
                </Col>
                <Col id="badgeSpacer" xs={2} className="badge-spacer">
                  {x.badge && (
                    <Button
                      color="link"
                      onClick={() => toggleModal(true, x.badge)}
                      className="external-link"
                    >
                      <FontAwesomeIcon
                        icon="arrow-down"
                        className="ml-1 mt-0 mb-0 mr-2 pointer"
                      />
                    </Button>
                  )}
                  {props.currentUser.isAdministrator && (
                    <div className="right">
                      <Row>
                        <Col>
                          <button
                            id="editVerificationButton"
                            title="edit"
                            onClick={e => editVerification(e, x)}
                            className="small-link-button admin-hover"
                          >
                            <FontAwesomeIcon
                              icon="edit"
                              className="ml-1 mt-0 mb-0 mr-2 pointer blue"
                            />
                          </button>
                        </Col>
                        <Col>
                          <button
                            id="deactivateVerificationButton"
                            title="delete"
                            onClick={e => deactivateVerification(e, x.id)}
                            className="small-link-button admin-hover"
                          >
                            <FontAwesomeIcon
                              icon="trash-alt"
                              className="ml-1 mt-0 mb-0 mr-2 pointer coral"
                            />
                          </button>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Col>
              </Row>
            ))}
          {!product.evaluations ||
            (product.evaluations.length === 0 && (
              <Row>
                <Col id="noVerificationsDisplayText" xs={12} className="notification">
                  There are currently no verifications for this product
                </Col>
              </Row>
            ))}
          <Modal isOpen={showModal}>
            <ModalHeader>Copy Badge</ModalHeader>
            <ModalBody id="copyBadgeModal">
              <p>
                Copy the html below into your site or content management system
                to display your product's Veracity badge
              </p>
              <Row>
                <Col>
                  <pre className="badge">{modalContent}</pre>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button className="cancel" onClick={() => toggleModal(false, "")}>
                Cancel
              </Button>
              <CopyToClipboard
                text={modalContent}
                onCopy={() => toggleModal(false, "")}
              >
                <Button className="site">Copy to clipboard</Button>
              </CopyToClipboard>
            </ModalFooter>
          </Modal>
        </React.Fragment>
      )}
    </Container>
  );
};

export default Product;
