import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Alert from "react-s-alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Collapse, Container, Row } from "reactstrap";
import { api } from "../helpers";
import { Score } from ".";
import defaultProduct from "../img/default-product.png";
import shieldNone from "../img/shield-none.png";
import shieldPartial from "../img/shield-partial.png";
import shieldFull from "../img/shield-full.png";
import vImage from "../img/v.png";
import { readBuilderProgram } from "typescript";

const veracityLinkRoot = "https://veracityvs.link/";
const productsApiRoot = "product";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const getToggleState = (
  toggleStates,
  categoryIndex,
  subcategoryIndex,
  criteriaIndex
) => {
  if (criteriaIndex || criteriaIndex === 0) {
    return toggleStates[categoryIndex]["subcategories"][subcategoryIndex][
      "criteria"
    ][criteriaIndex].on;
  }

  if (subcategoryIndex || subcategoryIndex === 0) {
    return toggleStates[categoryIndex]["subcategories"][subcategoryIndex].on;
  }

  return toggleStates[categoryIndex].on;
};

const ReportHeader = (props) => {
  return (
    <Row className="row-top-spacer report-product-header-container">
      <Col xs={12} className="report-product-header">
        <Row>
          <div className="report-product-icon">
            <img
              id="reportProductIcon"
              src={
                props.productEvaluation.productHasIconBytes
                  ? `api/${productsApiRoot}/icon?productId=${props.productEvaluation.productId}`
                  : `${defaultProduct}`
              }
              title="product-icon"
              alt="product-icon"
            />
          </div>
          <Col>
            <div className="product-name-container">
              <h2 id="evaluationProductName" className="product-name">
                {" "}
                {props.productEvaluation.productName}
              </h2>
            </div>
            <div id="evaluationProductProvider">
              by {props.productEvaluation.productProvider}
            </div>
          </Col>
        </Row>
        <div
          id="evaluationProductDescription"
          className="product-description-container"
        >
          {props.productEvaluation.productDescription}
        </div>
      </Col>
    </Row>
  );
};

const GetBarNumberForScore = (score) => {
  if (!score) {
    return 0;
  }
  return Math.floor(score);
};

const BadgeAndGraph = (props) => {
  return (
    <Row className="row-top-spacer">
      <Col xs={12} sm={4} className="overall-score-container">
        <Row className="score-out-of-container">
          <Col id="evaluationScoreContainer">
            <Score
              overallScore={props.productEvaluation.overallScore}
              scoreNumber={props.productEvaluation.scoreNumber}
              scoreDecimal={props.productEvaluation.scoreDecimal}
              outOf={" out of 5"}
            />
          </Col>
        </Row>
        <Row className="overall-container">
          <Col>&nbsp;</Col>
        </Row>
        <Row className="row-top-spacer">
          <Col>
            <img
              id="evaluationOverallImage"
              src={`${veracityLinkRoot}${props.reportJson.overallcertificate}`}
              alt="badge"
            />
          </Col>
        </Row>
        <Row className="completed-container">
          <Col>
            <span id="evaluationOverallCompletedTitle">Completed</span> &nbsp;{" "}
            {props.reportJson.verified}
          </Col>
        </Row>
      </Col>
      <Col xs={12} sm={8} className="row-top-spacer">
        {props.reportJson.categories &&
          props.reportJson.categories.map((x, i) => (
            <Row key={`cat-bar-name-${i}`}>
              <Col
                id={`categoryBarChartName-${i}`}
                xs={4}
                className="category-bar-chart-name "
              >
                {x.name}
              </Col>
              <Col xs={8} className="category-score-bar-container">
                <div
                  id={`categoryBarChartScore-${i}`}
                  className={`barchart-${GetBarNumberForScore(x.score)}`}
                >
                  {x.score}
                </div>
              </Col>
            </Row>
          ))}
      </Col>
    </Row>
  );
};

const ReportSectionHeader = (props) => {
  return (
    <Row>
      <Col className="report-section-header">
        <img src={vImage} alt="v" /> {props.headerNameWithNoV}{" "}
      </Col>
      {props.headerNameWithNoV === "erification Details" && (
        <Col xs="7" lg="4" className="expand-collapse-all px-1">
          <div className="float-right w-100">
            <h3 className="w-100">
              <Row className="w-100">
                <Col
                  onClick={() =>
                    props.setDefaultToggleStates(props.reportJson, true)
                  }
                  className="pointer px-1"
                  xs="5"
                >
                  Expand
                  <FontAwesomeIcon
                    id="expandVerificationDetailsButton"
                    icon="plus-square"
                    className="ml-1 mt-0 mb-0 mr-1 pointer report-section-icon expandCollapse  d-inline"
                  /> /{" "}
                </Col>
                <Col
                  onClick={() =>
                    props.setDefaultToggleStates(props.reportJson, false)
                  }
                  className="pointer px-1"
                  xs="7"
                >
                  Collapse All
                  <FontAwesomeIcon
                    id="collapseVerificationDetailsButton"
                    icon="minus-square"
                    className="ml-1 mt-0 mb-0 mr-1 pointer report-section-icon expandCollapse d-inline"
                  />
                </Col>
              </Row>{" "}
            </h3>
          </div>
        </Col>
      )}
    </Row>
  );
};

const VerificationSummary = (props) => {
  return (
    <Row>
      <Col>
        <ReportSectionHeader headerNameWithNoV="erification Summary" />
        <Row>
          <Col id="verificationSummaryHeader" className="report-product-header">
            {props.reportJson.summary}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const VerifiedClaims = (props) => {
  return (
    props.reportJson.claims &&
    props.reportJson.claims.length > 0 && (
      <Row>
        <Col>
          <ReportSectionHeader
            id="verifiedClaimsHeader"
            headerNameWithNoV="erified Claims"
          />
          {props.reportJson.claims.map((x, i) => (
            <Row key={`claim-${i}`} className="claims-container">
              <Col
                id={`changeClaimsToggleStateButton-${i}`}
                xs={2}
                sm={1}
                className="claims-shield-container"
                onClick={() => props.changeClaimToggleState(i)}
              >
                <div>
                  <img id={`claimImage-${i}`} src={shieldFull} alt="claim" />
                </div>
              </Col>
              <Col xs={10} sm={11} className="claims-content-wrapper">
                <Row>
                  <Col
                    id={`claimsHeader-${i}`}
                    onClick={() => props.changeClaimToggleState(i)}
                    className="claims-header"
                  >
                    {x.text}
                  </Col>
                </Row>
                {props.claimToggleStates && props.claimToggleStates[i] && (
                  <Row>
                    <Col>
                      {x.notes && (
                        <React.Fragment>
                          <Row>
                            <Col
                              id={`claimsNotedHeader-${i}`}
                              className="claims-subsection-header"
                            >
                              - Notes
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              id={`claimsNotes-${i}`}
                              className="claims-notes"
                            >
                              {x.notes}
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                      {x.attachments && x.attachments.length > 0 && (
                        <Row>
                          <Col
                            id={`claimsAttachmentsHeader-${i}`}
                            className="claims-subsection-header"
                          >
                            - Attachments
                          </Col>
                        </Row>
                      )}
                      {x.attachments.map((a, ai) => (
                        <Row key={`claim-${i}-attachment-${ai}`}>
                          {" "}
                          <Col className="attachment-icon-container">
                            <FontAwesomeIcon
                              icon="file"
                              className="ml-1 mt-0 mb-0 mr-1 pointer"
                            />
                            <a
                              id={`attachmentLink-${ai}`}
                              className="attachment-link"
                              href={a.link}
                            >
                              {a.name}
                            </a>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    )
  );
};

const getShieldImage = (value) => {
  switch (value) {
    case "Yes":
      return `${shieldFull}`;
    case "Partial":
      return `${shieldPartial}`;
    default:
      return `${shieldNone}`;
  }
};

const getScoreBarColor = (score) => {
  if (score <= 1) return "rgb(179,216,245)";
  if (score <= 2) return "rgb(108, 168, 216)";
  if (score <= 3) return "rgb(57, 133, 193)";
  if (score <= 4) return "rgb(35, 112, 174)";
  if (score <= 5) return "rgb(9, 83, 144)";

  return "rgb(9, 83, 144)";
};

const VerificationSubcategory = (props) => {
  return (
    <React.Fragment>
      <Row
        onClick={() =>
          props.changeToggleState(props.categoryIndex, props.index)
        }
      >
        <Col>
          <span
            id={`subcategoryName-${props.index}`}
            className="subcategory-name"
          >
            {props.subcategory.name}
          </span>
        </Col>
        <Col xs={2} className="category-icon-container">
          <FontAwesomeIcon
            icon={
              getToggleState(
                props.toggleStates,
                props.categoryIndex,
                props.index
              )
                ? "minus-square"
                : "plus-square"
            }
            className="ml-1 mt-0 mb-0 mr-1 pointer right"
          />
        </Col>
      </Row>
      <Collapse
        isOpen={getToggleState(
          props.toggleStates,
          props.categoryIndex,
          props.index
        )}
      >
        <hr className="category-rule" />
        <Row>
          <Col>
            {props.subcategory.criteria &&
              props.subcategory.criteria.map((x, i) => (
                <React.Fragment key={`subcat-${props.index}-${i}`}>
                  <Row>
                    <Col id={`criteriaHeader-${i}`} className="criteria-header">
                      <img src={getShieldImage(x.value)} alt="rubrick-value" />{" "}
                      <span
                        onClick={() =>
                          props.changeToggleState(
                            props.categoryIndex,
                            props.index,
                            i
                          )
                        }
                      >
                        {x.name}
                      </span>
                    </Col>
                  </Row>
                  <Collapse
                    isOpen={getToggleState(
                      props.toggleStates,
                      props.categoryIndex,
                      props.index,
                      i
                    )}
                  >
                    <Row
                      id={`criteriaDetailRow-${i}`}
                      className="criteria-detail-row"
                    >
                      <Col xs={10}>
                        <span id={`explanation-${i}`}>{x.explanation}</span>
                      </Col>
                      <Col xs={2} className="criteria-detail-close-container">
                        <FontAwesomeIcon
                          onClick={() =>
                            props.changeToggleState(
                              props.categoryIndex,
                              props.index,
                              i
                            )
                          }
                          icon="times"
                          className="ml-1 mt-0 mb-0 mr-1 pointer criteria-detail-close"
                        />
                      </Col>
                    </Row>
                  </Collapse>
                </React.Fragment>
              ))}{" "}
          </Col>
        </Row>
      </Collapse>
    </React.Fragment>
  );
};

const VerificationDetail = (props) => {
  return (
    <Row className="row-top-spacer">
      <Col>
        <Row>
          <Col>
            <span
              id={`verificationDetailCategoryHeader-${props.index}`}
              className="category-header"
            >
              {props.category.name}
            </span>{" "}
            <FontAwesomeIcon
              onClick={() => props.changeToggleState(props.index)}
              icon="info-circle"
              className="ml-1 mt-0 mb-0 mr-1 pointer info-circle"
            />
          </Col>
        </Row>
        <Collapse isOpen={getToggleState(props.toggleStates, props.index)}>
          <Row>
            <Col
              id={`verificationDetailCategoryDescription-${props.index}`}
              className="category-description"
            >
              {" "}
              {props.category.summary}{" "}
              <FontAwesomeIcon
                onClick={() => props.changeToggleState(props.index)}
                icon="times"
                className="ml-1 mt-0 mb-0 mr-1 pointer category-description-close-icon"
              />
            </Col>
          </Row>
        </Collapse>
        <Row className="guardians-container">
          <Col>
            {" "}
            <fieldset>
              <legend>Here's what our guardians had to say:</legend>
              <Row>
                <Col xs={7} md={9} className="average-rating-container">
                  <Row>
                    <Col
                      id={`averageGuardianRating-${props.index}`}
                      className="average-rating"
                    >
                      Average Guardian Rating
                    </Col>
                  </Row>
                  <div className="background-bar">
                    {""}
                    <div
                      className="score-bar"
                      style={{
                        width: `${(props.category.score || 0) * 20}%`,
                        backgroundColor: `${getScoreBarColor(
                          props.category.score || 0
                        )}`,
                      }}
                    >
                      {props.category.score}
                    </div>
                  </div>
                </Col>
                <Col
                  xs={5}
                  sm={1}
                  md={3}
                  className="category-score-container right"
                >
                  <Score
                    id={`categoryOverallScore-${props.index}`}
                    overallScore={props.category.score}
                  />
                </Col>
              </Row>
              <Row className="ease-of-use-summary">
                <Col>
                  <Row>
                    <Col>
                      <span
                        id={`easeOfUseSummaryHeader-${props.index}`}
                        className="ease-of-use-summary-header"
                      >
                        {props.category.name} Summary
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      id={`easeOfUseText-${props.index}`}
                      className="ease-of-use-text"
                    >
                      {props.category.notes}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {props.mode === "full" && props.category.recommendations && (
                <Row className="ease-of-use-recommendations">
                  <Col>
                    <Row>
                      <Col>
                        <span
                          id={`easeOfUseRecommendationsHeader-${props.index}`}
                          className="ease-of-use-summary-header"
                        >
                          {props.category.name} Recommendations
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        id={`easeOfUseRecommendations-${props.index}`}
                        className="ease-of-use-text"
                      >
                        {props.category.recommendations}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </fieldset>{" "}
          </Col>
        </Row>
        <Row className="subcategories-container">
          {props.category.subcategories &&
            props.category.subcategories.map((x, i) => (
              <Col xs={11} md={6} sm={6} key={`subcat-${i}`}>
                <Row>
                  <Col
                    id={`verificationSubCategoryContainer-${i}`}
                    className="subcategory-container"
                  >
                    <VerificationSubcategory
                      subcategory={x}
                      categoryIndex={props.index}
                      index={i}
                      changeToggleState={props.changeToggleState}
                      toggleStates={props.toggleStates}
                    />
                  </Col>
                </Row>
              </Col>
            ))}
        </Row>
      </Col>
    </Row>
  );
};

const VerificationDetails = (props) => {
  return (
    <Row>
      <Col>
        <ReportSectionHeader
          id={`verificationsDetails`}
          headerNameWithNoV="erification Details"
          reportJson={props.reportJson}
          setDefaultToggleStates={props.setDefaultToggleStates}
        />
        {props.reportJson.categories &&
          props.reportJson.categories.map((x, i) => (
            <VerificationDetail
              id={`verificationDetail-${i}`}
              key={`category-${i}`}
              category={x}
              index={i}
              toggleStates={props.toggleStates}
              changeToggleState={props.changeToggleState}
              mode={props.mode}
            />
          ))}
      </Col>
    </Row>
  );
};

const EvaluationReport = (props) => {
  const [productEvaluationId] = useState(useParams().productevaluationid);
  const [mode] = useState(useParams().mode);
  const [productEvaluation, setProductEvaluation] = useState(null);
  const [reportJson, setReportJson] = useState(null);
  const [toggleStates, setToggleStates] = useState(null);
  const [claimToggleStates, setClaimToggleStates] = useState(null);

  const evaluationApiRoot = "product/evaluation";

  const setDefaultToggleStates = (json, state) => {
    if (!json || !json.categories) {
      return null;
    }

    let defaultToggleStates = {};

    for (var c = 0; c < json.categories.length; c++) {
      defaultToggleStates[c] = {
        on: state,
        subcategories: {},
      };
      if (
        json.categories[c].subcategories &&
        json.categories[c].subcategories.length > 0
      ) {
        for (var s = 0; s < json.categories[c].subcategories.length; s++) {
          defaultToggleStates[c]["subcategories"][s] = {
            on: state,
            criteria: {},
          };
          if (
            json.categories[c].subcategories[s].criteria &&
            json.categories[c].subcategories[s].criteria.length > 0
          ) {
            for (
              var i = 0;
              i < json.categories[c].subcategories[s].criteria.length;
              i++
            ) {
              defaultToggleStates[c]["subcategories"][s].criteria[i] = {
                on: state,
              };
            }
          }
        }
      }
    }

    setToggleStates(defaultToggleStates);
  };

  const setDefaultClaimToggleStates = (json) => {
    if (!json.claims) {
      setClaimToggleStates([]);
    }

    setClaimToggleStates(json.claims.map((x) => false));
  };

  const loadProductEvaluation = () => {
    api
      .fetch(`${evaluationApiRoot}?productEvaluationId=${productEvaluationId}`)
      .then((response) => {
        if (
          response.data &&
          ((mode === "full" && response.data.serializedReportJson) ||
            response.data.serializedPublicReportJson)
        ) {
          const json =
            mode === "full"
              ? JSON.parse(response.data.serializedReportJson)
              : JSON.parse(response.data.serializedPublicReportJson);

          setReportJson(json);
          setDefaultToggleStates(json, false);
          setDefaultClaimToggleStates(json);
          setProductEvaluation(response.data);
        }
      })
      .catch((error) => {
        Alert.error("There was an error loading the product verification");
      });
  };

  const changeToggleState = (
    categoryIndex,
    subcategoryIndex,
    criteriaIndex
  ) => {
    if (criteriaIndex || criteriaIndex === 0) {
      setToggleStates({
        ...toggleStates,
        [categoryIndex]: {
          ...toggleStates[categoryIndex],
          subcategories: {
            ...toggleStates[categoryIndex]["subcategories"],
            [subcategoryIndex]: {
              ...toggleStates[categoryIndex]["subcategories"][subcategoryIndex],
              criteria: {
                ...toggleStates[categoryIndex]["subcategories"][
                  subcategoryIndex
                ]["criteria"],
                [criteriaIndex]: {
                  on: !toggleStates[categoryIndex]["subcategories"][
                    subcategoryIndex
                  ]["criteria"][criteriaIndex]["on"],
                },
              },
            },
          },
        },
      });
      return;
    }

    if (subcategoryIndex || subcategoryIndex === 0) {
      setToggleStates({
        ...toggleStates,
        [categoryIndex]: {
          ...toggleStates[categoryIndex],
          subcategories: {
            ...toggleStates[categoryIndex]["subcategories"],
            [subcategoryIndex]: {
              ...toggleStates[categoryIndex]["subcategories"][subcategoryIndex],
              on: !toggleStates[categoryIndex]["subcategories"][
                subcategoryIndex
              ]["on"],
            },
          },
        },
      });
      return;
    }

    setToggleStates({
      ...toggleStates,
      [categoryIndex]: {
        ...toggleStates[categoryIndex],
        on: !toggleStates[categoryIndex]["on"],
      },
    });
  };

  const changeClaimToggleState = (index) => {
    let newClaimToggleStates = claimToggleStates.slice();
    newClaimToggleStates[index] = !newClaimToggleStates[index];
    setClaimToggleStates(newClaimToggleStates);
  };

  window.pendo.initialize({
    visitor: {
      id: props.currentUser.id, //'VISITOR-UNIQUE-ID'   // Required if user is logged in
      email: props.currentUser.email,
      first_name: props.currentUser.firstName,
      last_name: props.currentUser.lastName,
      role: props.currentUser.role,
      has_pro_products: props.currentUser.hasProProducts,
      is_administrator: props.currentUser.isAdministrator,
      product_ids: props.currentUser.productIds,
      product_names: props.currentUser.productNames, // Recommended if using Pendo Feedback, or NPS Email
      // full_name:    // Recommended if using Pendo Feedback
      // role:         // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: "ACCOUNT-UNIQUE-ID", // Required if using Pendo Feedback
      // name:         // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    },
  });

  useEffect(loadProductEvaluation, []);

  return (
    <Container>
      {productEvaluation && reportJson && toggleStates && claimToggleStates && (
        <React.Fragment>
          <Row>
            <Col>
              <Link id="myProductLink" to={"/"}>
                My Products{" "}
              </Link>{" "}
              &gt;{" "}
              <Link to={`/product/${productEvaluation.productId}`}>
                {productEvaluation.productName}{" "}
              </Link>{" "}
            </Col>{" "}
          </Row>{" "}
          <ReportHeader productEvaluation={productEvaluation} />{" "}
          <BadgeAndGraph
            productEvaluation={productEvaluation}
            reportJson={reportJson}
          />{" "}
          <VerificationSummary reportJson={reportJson} />{" "}
          <VerifiedClaims
            reportJson={reportJson}
            claimToggleStates={claimToggleStates}
            changeClaimToggleState={changeClaimToggleState}
          />{" "}
          <VerificationDetails
            reportJson={reportJson}
            toggleStates={toggleStates}
            changeToggleState={changeToggleState}
            setDefaultToggleStates={setDefaultToggleStates}
            mode={mode}
          />{" "}
          <div onClick={() => scrollToTop()} title="Scroll to Top">
            {" "}
            <div className="back-to-top-wrapper">
              {" "}
              <span>
                <FontAwesomeIcon
                  icon="arrow-up"
                  className="ml-1 mt-0 mb-0 mr-1 pointer 2x"
                />
              </span>
            </div>
          </div>
          <div id="mySidebar" className="sidebar">
            <Row>
              <Col>
                <FontAwesomeIcon
                  icon="question"
                  className="ml-1 mt-0 mb-0 mr-1 pointer"
                />
                <span id="criteriaLegend" className="icon-text bold">
                  ATTRIBUTE LEGEND
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <img src={shieldFull} alt="confirmed" />
                <span id="meetsCriteria" className="icon-text">
                  Full evidence for this attribute was identified.
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <img src={shieldPartial} alt="partial" />
                <span id="partiallyMeetsCriteria" className="icon-text">
                  Some evidence for this attribute was identified.
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <img src={shieldNone} alt="no evidence" />
                <span id="noEvidenceIdentified" className="icon-text">
                  No evidence for this attribute was identified.
                </span>
              </Col>
            </Row>
          </div>
        </React.Fragment>
      )}
    </Container>
  );
};

export default EvaluationReport;
