import React, { useState } from "react";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import Alert from "react-s-alert";
import { api } from "../helpers";
import logo from "../img/logo_large.png";
import queryString from "query-string";
import { Redirect } from "react-router";
import { Link } from "react-router-dom";

const ChangePassword = (props) => {
  const params = queryString.parse(props.location.search);
  const email = params.email;
  const token = params.token;

  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [showreset, setShowReset] = useState(false);

  const changePassword = (e) => {
    if (e) {
      e.preventDefault();
    }
    const errorMessage = "There was an error changing your password";

    if (!password || !confirmation) {
      Alert.warning("Password and confirmation are required");
      return;
    }

    if (password !== confirmation) {
      Alert.warning("Password and confirmation must match");
      return;
    }

    api
      .post("public/changepassword", {
        Email: email,
        Password: password,
        ResetPasswordToken: token,
      })
      .then((response) => {
        if (!response.data.result) {
          if (response.data.errorMessages) {
            Alert.error(response.data.errorMessages.join("<br/>"));
            setShowReset(true);
          } else {
            Alert.error(errorMessage);
          }
        } else {
          Alert.success("Your password has been reset");
          setRedirect(true);
        }
      })
      .catch((err) => Alert.error(errorMessage));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      changePassword();
    }
  };

  return redirect ? (
    <Redirect to="/login" />
  ) : (
    <Container>
      <Row className="login-logo-container">
        <Col className="center-col">
          <img src={logo} alt="Veracity" className="App-logo" />
        </Col>
      </Row>
      <Row className="row-top-spacer">
        <Col xs={8} sm={6} className="center-col">
          <h5 id="changePasswordTitle">Change Password</h5>
        </Col>
      </Row>
      <Row className="row-top-spacer">
        <Col xs={8} sm={4} className="center-col">
          <FormGroup>
            <Label for="password">Password</Label>
            <Input
              id="changePasswordTextbox"
              className="login"
              name="password"
              type="password"
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="confirmpassword">Confirm Password</Label>
            <Input
              id="changePasswordConfirmTextbox"
              className="login"
              name="confirmpassword"
              type="password"
              placeholder="Confirm password"
              onChange={(e) => setConfirmation(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="row-top-spacer">
        <Col xs={8} className="center-col">
          <button id="changePasswordButton" onClick={(e) => changePassword(e)} className="site">
            CHANGE PASSWORD
          </button>
        </Col>
      </Row>
      {showreset && (
        <Row className="row-top-spacer">
          <Col xs={6} className="center-col">
            <Link id="sendAnotherPasswordButton" className="small-link" to="/forgotpassword">
              Send another password reset
            </Link>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ChangePassword;
