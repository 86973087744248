import React, { useState, useEffect } from "react";
import { Col, Container, Row} from "reactstrap";
import termsPdf from "../content/terms-of-use.pdf";
import { Loader } from "./";

const TermsAndConditions = (props) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(function () {
            setIsLoading(false);
        }, 500);
    }, []);

    return (
        <Container className="mb-5 p-2">
                <Row>
                    <Col>
                    <p className="centerText">
                        <h1>Terms and Conditions</h1>
                        <h2>VERACITY MARKETPLACE</h2>
                        <h3>TERMS OF USE</h3>
                    </p>
                    <p>Last Updated: June 21, 2022</p>
                    <p>
                        Your use of the Veracity Marketplace at and through www.veracityvs.pro which is
                        owned by Veracity Verification Solutions, LLC (“Veracity”) and any of the services,
                        products or opportunities offered by Veracity through any mobile or other applications
                        (collectively, the “Sites”), is expressly conditioned on your acceptance of these Terms of
                        Use (these “Terms”). By using the Sites, you signify your assent to these Terms and
                        they constitute a legal agreement between you and Veracity (the “Agreement”) which
                        will govern your use of the Sites and Services. In these Terms, the words “User,” “you”,
                        and “your” refer to each individual user who may interact with this site, and the words
                        “Veracity,” “we”, “us” and “our” refer to Veracity Verification Services, LLC, a limited
                        liability company organized under the laws of the Commonwealth of Virginia, United
                        States of America.
                    </p>
                    <p>
                        The term “Services” used in these Terms includes but is not limited to the Veracity
                        online library for access to Veracity’s Verification reports and other data and related
                        services, including documentation, free downloaded material or other information
                        available through the Sites and/or obtained from Veracity.
                    </p>
                    <p>
                        <strong>
                            If at any time you do not agree with these Terms or find them otherwise
                            unacceptable, please immediately cease using the Sites and any Services.
                        </strong>
                    </p>
                    <p className="centerText">
                        <h3>GRANT OF LICENSE</h3>
                    </p>
                    <p>
                        Subject to the other provisions of the Agreement, Veracity grants you, solely during the
                        term of your Usage Plan, a non-exclusive, non-transferable, non-assignable, and nonsublicensable
                        license to use the Sites and Services solely for your own internal
                        purposes. Veracity may unilaterally terminate this license at any time, with or without
                        cause or reason.
                    </p>
                    <p className="centerText">
                            <h3>REFUND POLICY</h3>
                        </p>
                    <p>No User will ever be entitled to any refund of any Fee or other amount paid to Veracity.</p>
                    <p className="centerText">
                        <h3>OWNERSHIP</h3>
                    </p>
                    <p>
                        Veracity retains all rights in the Services and the Sites and in all computer code, text, reports, data and data points, logos, images, trademarks, service marks, creative works, and other content used by Veracity in connection with the Services and the Sites. This
                        Agreement grants no ownership rights to User. No license is granted to User except to use the Services as expressly stated and limited above.
                        The Veracity name and logo, and the product names associated with the Services and Sites are trademarks of Veracity, and they may not be used without the prior written consent of Veracity.
                        User agrees not to reverse engineer, disassemble, decompile, or attempt to discover the source code of the Services or Sites or any Veracity software or other technology,
                        or attempt to circumvent any limitations on the use of the Services or Sites. User will not use or access the Services or Sites to: (i) build a competitive product or service; (ii) make a product using ideas, features, functions or graphics similar to those of the Services or Sites; (iii) make derivative works based upon the Services or Sites; (iv) copy any features, functions or graphics of the Services or Sites; or (v) lease, sell, distribute or otherwise allow any third party to use the Services or Sites.
                    </p>
                    <p className="centerText">
                        <h3>PROHIBITED USE</h3>
                    </p>
                    <p>
                        Actions prohibited on the Sites include:

                        <ol>
                            <li>any act that would constitute a breach of either the privacy rights or any other legal rights of other individuals;</li>

                            <li>using the Sites or Services as a forum to defame Veracity or its officers, directors, employees and agents, or otherwise acting in a manner that brings Veracity into disrepute;</li>

                            <li>uploading files that contain malware or viruses or that may otherwise cause harm, interruption or damage to the property of Veracity or the property of other individuals accessing the Sites and/or using the Services;</li>

                            <li>posting or providing to or through the Sites any libelous, defamatory, racist, obscene, threatening, treasonous, pornographic, profane or unlawful material;</li>

                            <li>any other actions that Veracity may deem inappropriate; and</li>

                            <li>any copying or sharing of content on the sites with third parties or use other than for User’s internal purposes.</li>

                        </ol>
                        User acknowledges and agrees that Veracity may examine and analyze User’s use of the Sites and Services in order to ensure that such use is in accordance with this Agreement.
                    </p>
                    <p className="centerText">
                        <h3>UNAUTHORIZED ACCESS</h3>
                    </p>
                    <p>
                        User is responsible for all activities that occur under User’s accounts. User is responsible for maintaining the security and confidentiality of all usernames and passwords.
                    </p>
                    <p className="centerText">
                        <h3>WARRANTIES AND DISCLAIMERS</h3>
                    </p>
                    <p>
                        <strong>
                            Use of the Sites and Services is at your own and exclusive risk. Veracity assumes no responsibility for the information made available to you through the Sites and Services and disclaims all representations and warranties that any information provided via the Sites or Services is accurate, complete,
                            or not misleading as the reports rely on the accuracy of data and other information provided others.
                        </strong>
                    </p>
                    <p>
                        <strong>
                            THE SITES AND THE SERVICES ARE PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS AND, CONSEQUENTLY, VERACITY MAKES NO WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE (INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY OR SATISFACTORY QUALITY, NONINFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE) INCLUDING THAT ACCESS TO THE SITES OR THE SERVICES WILL BE UN-INTERRUPTED, THAT THE SERVICES OR THE RESULTS THEREOF WILL BE ACCURATE; ERROR-FREE, COMPLETE OR SUFFICIENT FOR USER’S PURPOSES OR NEEDS, THAT THE SERVICES OR SITES WILL BE FREE FROM VIRUSES OR MALWARE, THAT THE SERVICES OR THE SITES WILL BE SECURE OR RELIABLE, OR THAT ANY OF YOUR PERSONAL INFORMATION WILL NOT BE DISCLOSED ACCIDENTALLY OR THROUGH THE ILLEGAL ACTS OF THIRD PARTIES, AND ANY SUCH REPRESENTATIONS OR WARRANTIES ARE ACCORDINGLY EXPRESSLY DISCLAIMED. YOU ACKNOWLEDGE THAT VERACITY WILL NOT BACK UP ANY CLIENT DATA OR ANY OF THE RESULTS OF YOUR USE OF THE SERVICES, AND AGREE TO MAINTAIN YOUR OWN BACKUPS OF THE CLIENT DATA AND OF SUCH RESULTS,
                            AND FURTHER AGREE THAT VERACITY IS NOT RESPONSIBLE IF ANY OF SUCH CLIENT DATA OR RESULTS SHOULD BE LOST OR BECOME CORRUPTED OR OTHERWISE UNUSABLE.
                        </strong>
                    </p>
                    <p className="centerText">
                        <h3>LIMITATION OF LIABILITY</h3>
                    </p>
                    <p>
                        You agree that neither Veracity nor any other parties involved in creating or providing the Sites or the Services on behalf of Veracity shall have any liability or responsibility whatsoever for any direct, incidental, consequential, indirect, special or punitive damages, costs, losses or liabilities related to or arising out of your access to, your use of, your intermittent or permanent inability to access or use, the content of, or any change in content of, the Sites or Services (even if Veracity has been advised of the
                        possibility of such damages). Veracity disclaims, to the extent permitted by applicable law, all liability for any actions it takes or fails to take in response to any communications you may send to Veracity.
                    </p>
                    <p>
                        <strong>
                            VERACITY DISCLAIMS ANY LIABILITY FOR YOUR USE OF THE SERVICES AND/OR THE SITES. THOSE WHO USE THE SERVICES AND/OR THE SITES DO SO AT THEIR OWN RISK AND COST, ARE RESPONSIBLE FOR COMPLIANCE WITH APPLICABLE LAW, AND SHALL BE SOLELY RESPONSIBLE FOR ANY DAMAGES SUFFERED BY OR CAUSED TO THIRD PARTIES,
                            INCLUDING THOSE ARISING FROM LOSS OF DATA, OR FROM THE USE OF OR ACCESS TO THE SERVICES AND/OR SITES. UNDER NO CIRCUMSTANCES WHATSOEVER WILL VERACITY BE LIABLE FOR ANY DAMAGE, COSTS, OR OTHER HARM THAT USER MAY INCUR AS A RESULT OF USER’S USE OF THE SERVICES OR THE SITES.
                        </strong>
                    </p>
                    <p>
                        <strong>
                            If you are dissatisfied with the Services or the Sites or these Terms, your sole and exclusive remedy is to discontinue using the Services and/or the Sites.
                        </strong>
                    </p>
                    <p className="centerText">
                        <h3>INDEMNIFICATION</h3>
                    </p>
                    <p>
                        Each User agrees to fully indemnify and hold harmless Veracity from and against any costs, fees, damages, settlement payments, or other amounts or harm that Veracity may incur (including but not related to attorneys’ fees) that arise from or relate to (i) any breach by such User of any provision of these Terms; (ii) any breach of any representation or warranty made by such User in these Terms; or (iii) any claim brought by any other person as a result of or arising from any action that the User has undertaken through use of the Services or Sites.
                    </p>
                    <p className="centerText">
                        <h3>JURISDICTION AND GOVERNING LAW; LANGUAGE; COSTS</h3>
                    </p>
                    <p>
                        These Terms shall be construed in accordance with, and governed in all respects by, the laws of the Commonwealth of Virginia, without respect to its choice of law provisions. Any dispute between the parties concerning this Agreement shall be subject to exclusive jurisdiction and venue before the state and federal courts in or for Roanoke City, Virginia. If these Terms are provided to the User in any language other than English, then the version in such other language will be a translation only and the English version of these Terms will control. In any dispute arising from or related to these Terms, the prevailing party will be entitled to an award of its costs and fees incurred in connection with the dispute, including but not limited to its attorneys’ fees.
                    </p>
                    <p className="centerText">
                        <h3>CHANGES TO THESE TERMS</h3>
                    </p>
                    <p>
                        Veracity reserves the right to change or modify these Terms at any time without notice to you. Any such change will be effective immediately upon publication of any newly revised Terms at [www.veracityvs.pro ]. You are reminded that it is your responsibility to review these Terms prior to any use of the Sites and Services.
                    </p>
                    <p className="centerText">
                        <h3>PRIVACY</h3>
                    </p>
                    <p>
                        You have read the Privacy Policy applicable to the Sites located at <a href="https://www.veracityvs.pro" target="_blank">www.veracityvs.pro</a> (the “Privacy Policy”). You agree that the Terms of the Privacy Policy are reasonable and you consent to the use of your personal information by Veracity in accordance with the Privacy Policy.
                    </p>
                    <p className="centerText">
                        <h3>DISPUTE RESOLUTION</h3>
                    </p>
                    <p>
                        To expedite resolution and reduce the cost of any Dispute, controversy or claim related to these Terms (“Dispute”), you and Veracity agree to first attempt to negotiate any Dispute (except those Disputes expressly excluded below) informally for at least thirty
                        (30) days before initiating any arbitration or court proceeding. Such informal negotiations will commence upon written notice. Your address for such notices is your billing address, with an email copy to the email address you have provided to Veracity. Veracity’s address for such notice is Veracity Verification Services, LLC, PO Box 4144, Roanoke, VA 24015.
                    </p>
                    <p className="centerText">
                        <h3>ENFORCEMENT</h3>
                    </p>
                    <p>
                        If Veracity takes any action to enforce these Terms, Veracity will be entitled to recover from you, and you agree to pay, all reasonable and necessary attorneys’ fees and any cost of litigation, in addition to any other relief, at law or in equity, to which Veracity may be entitled. You acknowledge that a violation or attempted violation of any provision of these Terms will cause such damage to Veracity as will be irreparable, the exact amount of which would be difficult to ascertain and for which there will be no adequate remedy at law. Accordingly, you agree that Veracity shall be entitled as a matter of right to an injunction issued by any court of competent jurisdiction, restraining such violation or attempted violation of these Terms by you, or your affiliates, partners, or agents, as well as recover from you any and all costs and expenses sustained or incurred by Veracity in obtaining such an injunction, including, without limitation, reasonable
                        attorneys’ fees. You agree that no bond or other security shall be required in connection with such injunction.
                    </p>
                    <p className="centerText">
                        <h3>GENERAL</h3>
                    </p>
                    <p>
                        No agency, partnership, joint venture, or employment is created as a result of these Terms and you do not have any authority of any kind to bind Veracity in any respect whatsoever. You may not assign, convey, subcontract or delegate your rights, duties or obligations hereunder. Veracity may assign, convey, subcontract or delegate these
                        Terms or any rights or obligations hereunder without restriction. In the event that any provision of these Terms is determined to be unenforceable or invalid, such provision shall nonetheless be enforced to the fullest extent permitted by applicable law, and such determination shall not affect the validity and enforceability of any other remaining provisions of these Terms. These Terms, together with the Privacy Policy any agreements or other Terms and conditions incorporated or referred to herein constitute the entire agreement between us relating to the subject matter hereof, and supersede any prior understandings or agreements whether oral or written.
                    </p>
                    <p>
                        Failure by Veracity to enforce any provision(s) of this Agreement will not be construed as a waiver of any provision or right. This Agreement constitutes the entire agreement between you and Veracity with respect to its subject matter. If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions will be enforced to the fullest extent possible, and the remaining provisions will remain in full force and effect. This Agreement may not be assigned or transferred by you without our prior written approval. We may assign or transfer this Agreement without your consent, including but not limited to assignments: (i) to a parent or subsidiary, (ii) to an acquirer of assets, or (iii) to any other successor or acquirer. Any assignment in violation of this section shall be null and void. This Agreement will inure to the benefit of Veracity, its successors and assigns.
                    </p>
                    <p>
                        YOU HEREBY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THESE TERMS OF USE AND PRIVACY POLICY, AND AGREE THAT YOUR USE OF THE PLATFORM IS AN ACKNOWLEDGMENT OF YOUR AGREEMENT TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
                    </p>
                    <p>
                        If you don’t agree to the Terms contained in this agreement, please exit the Sites and do not use the Services.
                    </p>
                    <p className="centerText">
                        <h3>QUESTIONS</h3>
                    </p>
                    <p>
                        Any questions regarding the above Terms may be directed to: <a href="https://www.veracityvs.com" target="_blank">www.veracityvs.com</a>
                    </p>
                    </Col>
                </Row>
        </Container>
    );
}

export default TermsAndConditions;