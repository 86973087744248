import React from "react";
import { Link } from "react-router-dom";

export default function PrivacyPolicy(props) {
  return (
    <div>
      <h1>VERACITY VERIFICATION SOLUTIONS' Privacy Policy</h1>
      <p>
        Veracity Verification Solutions, LLC., a Delaware limited liability
        company, is serious about protecting your privacy. This Privacy Policy
        ("Policy") covers how Veracity collects and uses the personal
        information you provide on our website and in conjunction with any of
        the services hosted at veracityvs.pro ("Service" and "Website"),
        including verification services accessed by internet enabled devices. It
        also describes the choices available to you regarding our use of your
        personal information and how you can access and update this information.
        This Policy also applies if you participate in our research and
        information verification projects.
      </p>
      <p>The Policy was last updated on March 22nd, 2021.</p>
      <p>
        This Policy explains what personal information we collect about you,
        with whom we share it, and how you can elect in certain situations to
        not share information with third parties. "Personal Information" refers
        to information such as name and email that can be used to identify you
        individually.
      </p>
      <p>
        We may amend this Policy at any time, and whenever we do so we will post
        a revised version on the Website. If, however, we plan to make a
        material change in the way we collect, use, or store your personal
        information, we will attempt to provide prior notice to you by email.
      </p>
      <p>
        You will then have the chance to decide whether or not we will use or
        disclose your information in that new manner, whether though an
        "opt-out" procedure or by ending your relationship with us. Such changes
        will become effective, at the earliest, 30 days after the date posted on
        our Website.
      </p>
      <p>
        Minors (those under the age of 18 years) are not eligible to use our
        Service and should refrain from using the Service or submitting any
        person information to us.
      </p>
      <p>
        If you have questions or concerns regarding this Policy, you should
        contact us at{" "}
        <a href="mailto:privacy@veracityvs.com">privacy@veracityvs.com</a>.
      </p>
      <h2>Information We Collect</h2>
      <p>
        We will collect the following information about you, which may include
        non-public personal information:
      </p>
      <ol>
        <li>
          Personal and financial Information you give us as a result of using
          the Service, such as your address, e-mail address, telephone number,
          and occupation (Including Title, Position, Employer, etc.).
        </li>
        <li>
          Information about your usage of the Service, including your
          transaction history, and how and to whom you use the Service to verify
          information;
        </li>
        <li>
          Information that we lawfully obtain from third parties, such as
          identity verification services, electronic database services, and
          credit reporting agencies;
        </li>
        <li>
          Information that we indirectly obtain from you, such as information
          about the hardware and software you use when accessing the Service,
          your IP address, and the pages you access on this website, and other
          websites that you visit prior to accessing the Service.
        </li>
      </ol>
      <p>
        We partner with a third party to manage our advertising on other sites.
        Our third-party partner may use technologies such as cookies to gather
        information about your activities on this site and other sites in order
        to provide you advertising based upon your browsing activities and
        interests. If you wish to not have this information used for the purpose
        of serving you interest-based ads, you may opt-out by clicking here.
        Please note this does not opt you out of being served ads. You will
        continue to receive generic ads.
      </p>
      <h2>Why We Collect Information</h2>
      <p>
        We collect Personal and Non-Personal information for the following
        purposes:
      </p>
      <ol>
        <li>To process your transactions</li>
        <li>To verify your identity</li>
        <li>
          To track, improve and personalize our Services, content and
          advertising
        </li>
        <li>To troubleshoot problems with the Service</li>
        <li>To comply with applicable laws and regulations.</li>
        <li>
          To detect and prevent fraud and other illegal uses of the Service
        </li>
        <li>
          To send you marketing notices, service updates, and promotional
          offers, and/or
        </li>
        <li>
          To collect survey information that will be used to monitor or improve
          the use of our Service and overall customer satisfaction.
        </li>
      </ol>
      <p>
        We will retain your information for as long as your account is active or
        as needed to provide you the Service. We will retain and use your
        information as necessary to comply with our legal obligations, resolve
        disputes, and enforce our agreements.
      </p>
      <h2>How We Share Information With Others</h2>
      <p>
        We will share your personal information with third parties only in the
        ways that are described in this privacy policy. We do not sell or rent
        the information we collect to third parties for their promotional
        purposes. These companies are authorized to use your personally
        identifiable information only as necessary to provide these services to
        us.
      </p>
      <p>
        Nevertheless, we may share information about you (including nonpublic,
        personal information) with:
      </p>
      <ol>
        <li>
          Third-party service providers under contract with Veracity that help
          us with our business operations, such as transaction processing, fraud
          prevention, marketing, market research, and validation of information
          provided. These third parties are prohibited from using your
          information for their own separate business purposes.
        </li>
        <li>
          In the event of the sale, acquisition or merger of some or all of our
          assets, your personal information might be a part of the transferred
          assets. We shall notify you in the event of such an occurrence, as
          well as any choices you may have regarding your personal information,
          by placing a notice on our website.
        </li>
        <li>
          Law enforcement, government officials or other third parties, but only
          <ul>
            <li>
              in connection with a formal request, subpoena, court order, or
              similar legal procedure; and,
            </li>
            <li>
              when we believe in good faith that disclosure is necessary to
              comply with the law, prevent physical harm or financial loss,
              report suspected illegal activity, or to investigate violations of
              our User Agreement.
            </li>
          </ul>
        </li>
        <li>
          Other third parties that you consent or direct, which may include you
          authorizing an account linking for a third-party service, account
          and/or platform.
          <ul>
            <li>
              For the purposes of this privacy policy, an "account linking" with
              such a third party is a connection you authorize or enable between
              your Veracity account and a non-Veracity account, or platform that
              you lawfully control or own. When you authorize such a connection,
              Veracity and the third-party will exchange your personal
              information and other information directly.
            </li>
            <li>
              Information that we share with a third-party based on an account
              connection will be used and disclosed in accordance with the
              third-party’s privacy practices. Before authorizing an account
              connection, you should review the privacy notice of any
              third-party that will gain access to your personal information as
              part of the account connection.
            </li>
          </ul>
        </li>
      </ol>
      <h2>Access To Your Information</h2>
      <p>
        You can request for your personal information to be modified or deleted
        by emailing our Customer Support at{" "}
        <a href="mailto:privacy@veracityvs.com">privacy@veracityvs.com</a>. We
        will respond to your request to access within a reasonable timeframe.
      </p>
      <h2>Research Participants</h2>
      <p>
        Personal information we collect: If you participate in any research
        projects for us (by phone, in-person, electronic survey or otherwise),
        we will hold your name, contact details and any other personal
        information you choose to give us when taking part in our research
        projects.
      </p>
      <p>
        Using your personal information: We will use this information for our
        legitimate interests namely to conduct our research projects which may
        also help us to improve and/or develop our products and services.
      </p>
      <p>
        Sharing your personal information: We will share this information with:
      </p>
      <ul>
        <li>
          our staff (but their use shall be limited to the performance of their
          duties and in line with the reason for processing);
        </li>
        <li>
          our platform, database, and website hosting suppliers to enable them
          to maintain, protect, support and host those solutions which are used
          to store our research;
        </li>
        <li>
          various third parties who provide software and tools which are used to
          operate our business (including email, instant messaging,
          document/contract management and file-sharing);
        </li>
        <li>
          third parties in the event of the sale, acquisition or merger of some
          or all of our assets if your personal information is part of the
          transferred assets (we shall notify you in the event of such an
          occurrence, as well as any choices you may have regarding your
          personal information, by placing a notice on our Site).
        </li>
      </ul>
      <p>
        Retaining your personal information: We will only retain your personal
        information for as long as necessary to fulfil the purposes we collected
        it for, including for the purposes of satisfying any legal, accounting
        or reporting requirements. To determine the appropriate retention period
        for personal information, we consider (amongst other things):
      </p>
      <ul>
        <li>
          obligations and/or retention periods imposed on us by applicable laws
          and/or our regulators
        </li>
        <li>the amount, nature, and sensitivity of the personal information</li>
        <li>
          the potential risk of harm from unauthorized use or disclosure of your
          personal information, and
        </li>
        <li>
          the purposes for which we process your personal information and
          whether we can achieve those purposes through other means.
        </li>
      </ul>
      <h2>Notice to Customers who reside in California</h2>
      <p>
        What are your rights with regard to the information you share with us?
      </p>
      <p>
        Certain countries and states’ privacy laws provide certain rights for
        individual consumers. For example, in California, residents have the
        right to request that the business disclose what personal information it
        collects, use, discloses, and sells. Veracity seeks to honor your
        privacy rights.
      </p>
      <p>
        While Veracity does not sell your personal information to any third
        party, you have the right to opt out of the sale of personal
        information. You also have the right to request information regarding
        the information Veracity may have collected about you through your
        interactions with Veracity. You may also request that Veracity modify,
        update, change, delete, or remove information that you have previously
        provided.
      </p>
      <p>
        If you desire to submit a verifiable consumer request to us, or to
        exercise your California privacy rights with regard to the information
        you may have shared with us, please contact us. A verifiable consumer
        request allows you to request that we disclose to you the personal
        information we have collected about you. You may also request that we
        delete the personal information we have collected about you. In order to
        verify your request, you may need to provide us with proof of your
        identity including, for example, providing us with a copy of your
        driver’s license. Under California, law, a consumer may also designate
        an authorized agent to make a request under the California Consumer
        Privacy Act. If you utilize an authorized agent, we may require you to
        submit proof that the agent acts on your behalf.
      </p>
      <p>
        If you are making a request for Veracity to delete information it has
        collected about you, please be sure to provide a description of the
        material that you want removed and information reasonably sufficient to
        allow us to locate the material and include "Privacy Request" in the
        subject line of the email. You may also ask us for a copy of Personal
        Information we maintain about you. Veracity will comply with reasonable
        requests to obtain a copy of your Personal Information consistent with
        legal requirements and limitations.
      </p>
      <p>
        We retain the information you share with us for as long as we need to in
        order to provide you the services you request. We also retain other data
        generated by you (i.e., data generated when you browse our website) in a
        depersonalized or aggregated form in order to help us improve users’
        experience on our website. We may also retain your information for a
        reasonable period of time for Veracity to conduct audits, comply with
        our legal obligations, resolve disputes, or other legitimate business
        interests.
      </p>
      <p>
        Under certain laws we are required to share with you our legal basis or
        business purpose for processing the personal information you share with
        us. We have attempted to specifically designate those legal bases or
        businesses purposes where applicable in this policy. In addition, we may
        also we process your personal data under the following additional
        scenarios:
      </p>
      <ul>
        <li>To comply with our legal and regulatory obligations;</li>
        <li>
          For the performance of our contract with you or to take steps at your
          request before entering into a contract;
        </li>
        <li>For our legitimate interests; and</li>
        <li>Where you have given consent for a specific use.</li>
      </ul>
      <p>
        Under California law, we cannot discriminate against you because you
        exercised your rights under the California Consumer Privacy Act. It is
        also important to note that you can enjoy Veracity’s website without
        providing personal data to us.
      </p>
      <p>
        If you desire to submit a verifiable consumer request to us, or to
        exercise your California privacy rights with regard to the information
        you may have shared with us, please contact us. In order to verify your
        request, you may need to provide us with proof of your identity
        including, for example, providing us with a copy of your driver’s
        license. Under California, law, a consumer may also designate an
        authorized agent to make a request under the California Consumer Privacy
        Act. If you utilize an authorized agent, we may require you to submit
        proof that the agent acts on your behalf.
      </p>
      <h2>Security</h2>
      <p>
        We employ industry accepted standards in protecting the information you
        submit to us on our Website.
      </p>
      <p>
        We have put in place SSL (Secure Socket Layer) encryption technology to
        protect your sensitive information such as name, occupation, and email
        transmitted through our Website. We also require a username and password
        from each user who wants to access their information on our Website.
        However, no method of transmission over the Internet, or method of
        electronic storage, is 100% secure. Therefore, we cannot guarantee its
        absolute security.
      </p>
      <p>
        If you have any questions about security on our Web site, you can
        contact us at{" "}
        <a href="mailto:privacy@veracityvs.com">privacy@veracityvs.com</a>.
      </p>
      <h2>Choices You Can Make About Our Use Of Your Information</h2>
      <p>
        Federal and state laws allow you to restrict the sharing of your
        personal information in certain instances. However, these laws also
        state that you cannot restrict all types of sharing. Because we have
        chosen to refrain from certain types of data sharing, the only type of
        sharing of your personal information that you may restrict is as
        follows:
      </p>
      <ol>
        <li>
          You may opt-out from promotional emails to you by selecting the
          "Unsubscribe" function present in each email we send you.
        </li>
      </ol>
      <h2>Links to 3rd Party Sites</h2>
      <p>
        Our Site includes links to other web sites whose privacy practices may
        differ from those of Veracity, Inc. If you submit personal information
        to any of those sites, your information is governed by their privacy
        policies. We encourage you to carefully read the privacy policy of any
        web site you visit.
      </p>
      <h2>Google Maps</h2>
      <p>
        Parts of the Veracity Service use Google Maps services, including the
        Google Maps API(s). Use of these features is subject to Google Maps
        Additional Terms of Use and the Google Privacy Policy.
      </p>
      <h2>Chat</h2>
      <p>
        We use a chat feature to answer questions about our service and assist
        you with our website's functionality. If our chat feature is offline we
        will collect your name and email address so that we may reply to your
        request.
      </p>
      <h2>Social Media Widgets</h2>
      <p>
        Our web site includes Social Media Features, such as the Facebook Like
        button and Widgets, such as the Share this button or interactive
        mini-programs that run on our site. These Features may collect your IP
        address, which page you are visiting on our site, and may set a cookie
        to enable the Feature to function properly. Social Media Features and
        Widgets are either hosted by a third party or hosted directly on our
        Site. Your interactions with these Features are governed by the privacy
        policy of the company providing it.
      </p>
      <h2>Import Contacts</h2>
      <p>
        You can import contacts from your Gmail and Yahoo Mail to invite them to
        become members of our site. We do not collect the username and password
        for the email account you wish to import your contacts from as you
        provide it directly to Gmail and Yahoo and after your approval they send
        us your email contacts. At your instruction we will send an email invite
        and at most one reminder email in addition to the original invite.
      </p>
      <h2>Referrals</h2>
      <p>
        If you choose to use our referral service to tell a friend about our
        site, we will ask you for your friend's name and email address. We will
        automatically send your friend an email and at most one reminder email
        inviting him or her to visit the site. Veracity stores this information
        for the sole purpose of sending this email and tracking the success of
        your referral.
      </p>
      <p>
        Your friend may request that we remove this information from our
        database by contacting us at{" "}
        <a href="mailto:privacy@veracityvs.com">privacy@veracityvs.com</a>.
      </p>
      <h2>Complaints</h2>
      <p>
        If you have a complaint, contact Veracity Customer Service, online; or
        by email at{" "}
        <a href="mailto:privacy@veracityvs.com">privacy@veracityvs.com</a>.
      </p>
      <h2>Contact</h2>
      <p>
        You can contact us with any questions or concerns at:{" "}
        <a href="mailto:privacy@veracityvs.com">privacy@veracityvs.com</a>
      </p>
      <h2>COOKIE POLICY</h2>
      <p>
        This Cookie Policy explains how we use tracking methods such as cookies
        (including pixels, SDKs, JavaScript, device identifiers, etc., which we
        refer to as "Trackers").
      </p>
      <p>
        <i>
          What Sort of Trackers or Cookies Do We Use on the Veracity Website?
        </i>
      </p>
      <p>
        We, like many businesses, use a variety of methods to track visitors to
        our website. Below are some examples of the Trackers we use.
      </p>
      <ol>
        <li>
          <i>Cookies</i>
          <ul>
            <li>
              A cookie is a small string of text that a website stores on a
              user’s browser. There are several types of cookies including:
              <ul>
                <li>
                  First Party Cookies – those cookies that we place on our own
                  website.
                </li>
                <li>
                  Third Party Cookies – those cookies that are set by other
                  companies whose functionality may be embedded into our website
                  (i.e., Facebook).
                </li>
                <li>
                  Session Cookies – temporary cookies that are stored on your
                  device when you visit our site and expire when you close out
                  our website.
                </li>
                <li>
                  Persistent Cookies – cookies that linger after you close out
                  our website. They can be set to expire in a period of time or
                  can be deleted manually.
                </li>
              </ul>
              <ul>
                You can choose to accept cookies in your browser settings. If
                you decline to use cookies, it may make your experience on our
                website more difficult.
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <i>How do I Manage Trackers or Cookies?</i>
          <ul>
            <li>
              You can manage your tracker settings in your browser’s settings.
              If you refuse trackers, you may not be able to use some of the
              functionality of the Veracity website. Most browsers automatically
              accept cookies but you can adjust these settings.
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
}
