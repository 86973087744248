import React, { Component } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import logo from "../img/logo.png";

export default class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <header>
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
          light
        >
          <Container>
            <NavbarBrand id="homeLink" tag={Link} to="/">
              <img
                src={logo}
                alt="Veracity"
                title={
                  this.props.currentUser &&
                  this.props.currentUser.isAdministrator
                    ? this.props.appVersion
                    : ""
                }
                className="App-logo"
              />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
            <Collapse
              className="d-sm-inline-flex flex-sm-row-reverse"
              isOpen={!this.state.collapsed}
              navbar
            >
              <ul className="navbar-nav flex-grow">
                {this.props.currentUser &&
                  this.props.currentUser.isAdministrator && (
                    <React.Fragment>
                      <NavItem>
                        <NavLink id="productsLink" tag={Link} className="text-dark" to="/">
                          Products
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink id="usersLink" tag={Link} className="text-dark" to="/users">
                          Users
                        </NavLink>
                      </NavItem>
                    </React.Fragment>
                  )}
                {this.props.currentUser &&
                  !this.props.currentUser.isAdministrator &&
                  this.props.currentUser.hasProProducts && (
                    <NavItem className="text-right">
                      <Row>
                        <Col>
                          <a href="mailto:info@veracityvs.com">
                            Contact Us{" "}
                          </a>
                        </Col>
                      </Row>
                    </NavItem>
                  )}
                <NavItem className="nav-name-wrapper">
                  <Row>
                    <Col>
                      {this.props.currentUser && (
                        <span id="loggedInUserName">
                          {this.props.currentUser.firstName}{" "}
                          {this.props.currentUser.lastName}
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Link
                        id="logOutLink"
                        className="logout"
                        onClick={() => this.props.logoff()}
                        to="/"
                      >
                        Log Out
                        <FontAwesomeIcon
                          icon="sign-out-alt"
                          className="ml-1 mt-0 mb-0 mr-1 pointer"
                        />
                      </Link>
                    </Col>
                  </Row>
                </NavItem>
              </ul>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}
