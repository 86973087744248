import axios from "axios";
import Alert from "react-s-alert";

const userToken = () => localStorage.getItem("token");

const commonHeaders = () => {
  return { Accept: "application/json" };
};

const headers = () => {
  var token = userToken();
  if (token) {
    return Object.assign(commonHeaders(), {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    });
  } else {
    return Object.assign(commonHeaders(), {
      "Content-Type": "application/json"
    });
  }
};

const apiRoot = "/api/";

const queryString = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&");
  return `${query.length ? "?" : ""}${query}`;
};

axios.interceptors.response.use(
  function(response) {
    const current_client_version = localStorage.getItem("version");
    const current_server_version = response.headers.version;
    if (
      current_client_version === null ||
      current_client_version !== current_server_version
    ) {
      if (
        current_client_version !== undefined &&
        current_server_version !== undefined
      ) {
        localStorage.setItem("version", current_server_version);
        Alert.warning(
          "This page is about to reload with an updated version..."
        );
        setTimeout(function() {
          window.location.reload(true);
        }, 2200);
      }
    }
    return response;
  },
  function(error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("loggedOnAt");
      localStorage.removeItem("currentUser");
      localStorage.removeItem("token");
      setTimeout(function() {
        window.location.href = "/login";
      }, 500);
    }
    if (error.response && error.response.status === 403) {
      Alert.error(
        "Your user account does not have the permissions to take this action"
      );
    }
    return error;
  }
);

export default {
  fetch(url, params = {}) {
    return axios.get(`${apiRoot}${url}${queryString(params)}`, {
      headers: headers(),
      withCredentials: true
    });
  },
  post(url, data) {
    return axios.post(`${apiRoot}${url}`, data, {
      headers: headers(),
      withCredentials: true
    });
  },
  postFormData(url, formData) {
    return axios.post(`${apiRoot}${url}`, formData, {
      headers: Object.assign(headers(), {
        "Content-Type": "multipart/form-data"
      }),
      withCredentials: true
    });
  },
  put(url, data) {
    return axios.put(`${apiRoot}${url}`, data, {
      headers: headers(),
      withCredentials: true
    });
  },
  putFormData(url, formData) {
    return axios.put(`${apiRoot}${url}`, formData, {
      headers: Object.assign(headers(), {
        "Content-Type": "multipart/form-data"
      }),
      withCredentials: true
    });
  },
  patch(url, data) {
    return axios.patch(`${apiRoot}${url}`, data, {
      headers: headers(),
      withCredentials: true
    });
  },
  delete(url) {
    return axios.delete(`${apiRoot}${url}`, {
      headers: headers(),
      withCredentials: true
    });
  },
  multi(apiCalls) {
    if (!apiCalls || !apiCalls.length || apiCalls.length === 0) {
      return null;
    }
    return axios.all(apiCalls);
  },
  getApiUrl(url, params = {}) {
    return `${apiRoot}${url}${queryString(params)}`;
  }
};
