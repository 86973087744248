import React, { Component } from "react";
import { Container } from "reactstrap";
import { NavMenu, Footer } from ".";

export default class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div className="mainDiv">
        {this.props.showMenu && (
          <NavMenu
            currentUser={this.props.currentUser}
            logoff={this.props.logoff}
            appVersion={this.props.appVersion}
          />
        )}
        <Container>{this.props.children}</Container>
      <Footer />
      </div>
    );
  }
}
